import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/builds/popsup/washoku-front-end/src/pages/.umi-production/LocaleWrapper.jsx';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        name: '403',
        path: '/403',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__403" */ '../403'),
            })
          : require('../403').default,
        exact: true,
      },
      {
        name: '404',
        path: '/404',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
            })
          : require('../404').default,
        exact: true,
      },
      {
        name: '500',
        path: '/500',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__500" */ '../500'),
            })
          : require('../500').default,
        exact: true,
      },
      {
        path: '/welcome',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__TranslationLayout" */ '../../layouts/TranslationLayout'),
            })
          : require('../../layouts/TranslationLayout').default,
        routes: [
          {
            name: 'welcome-ca',
            path: '/welcome/ca',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__welcome__ca__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/welcome/ca/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__welcome__ca" */ '../welcome/ca'),
                })
              : require('../welcome/ca').default,
            exact: true,
          },
          {
            path: '/welcome',
            redirect: '/welcome/ca',
            exact: true,
          },
          {
            path: '/welcome/**',
            redirect: '/404',
            exact: true,
          },
        ],
      },
      {
        path: '/ca',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__CaLayout" */ '../../layouts/CaLayout'),
            })
          : require('../../layouts/CaLayout').default,
        Routes: [require('../CaValidBusinessGate').default],
        routes: [
          {
            name: 'ca-previous-order',
            path: '/ca/previous-order',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__ca__previous-order__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/previous-order/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__ca__previous-order" */ '../ca/previous-order'),
                })
              : require('../ca/previous-order').default,
            exact: true,
          },
          {
            name: 'ca-cart',
            path: '/ca/cart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__ca__cart__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/cart/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__ca__cart" */ '../ca/cart'),
                })
              : require('../ca/cart').default,
            exact: true,
          },
          {
            name: 'ca-viewer-check',
            pageIndex: 'main',
            Routes: [require('../CaStaffAuthGate').default],
            routes: [
              {
                name: 'ca-order',
                path: '/ca/order',
                Routes: [require('../CaCartStatusGate').default],
                routes: [
                  {
                    name: 'ca-order-process',
                    path: '/ca/order/process',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__order__process__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/order/process/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/order/process'),
                        })
                      : require('../ca/order/process').default,
                    exact: true,
                  },
                  {
                    name: 'ca-order-payment',
                    path: '/ca/order/payment',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__order__payment__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/order/payment/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/order/payment'),
                        })
                      : require('../ca/order/payment').default,
                    exact: true,
                  },
                  {
                    name: 'ca-order-invoice',
                    path: '/ca/order/invoice',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__order__invoice__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/order/invoice/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/order/invoice'),
                        })
                      : require('../ca/order/invoice').default,
                    exact: true,
                  },
                  {
                    path: '/ca/order',
                    redirect: '/ca/order/process',
                    exact: true,
                  },
                  {
                    path: '/ca/order/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                name: 'ca-group',
                path: '/ca/group',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ca__group__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/group/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/group'),
                    })
                  : require('../ca/group').default,
                exact: true,
              },
              {
                name: 'ca-dishes',
                path: '/ca/dishes',
                routes: [
                  {
                    name: 'ca-dishes-list-all',
                    path: '/ca/dishes',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__dishes__list__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/dishes/list/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/dishes/list'),
                        })
                      : require('../ca/dishes/list').default,
                    exact: true,
                  },
                  {
                    name: 'ca-dishes-view-single',
                    path: '/ca/dishes/view/:id',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__dishes__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/dishes/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/dishes/view/$id'),
                        })
                      : require('../ca/dishes/view/$id').default,
                    exact: true,
                  },
                  {
                    path: '/ca/dishes/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                name: 'ca-menus',
                path: '/ca/menus',
                routes: [
                  {
                    name: 'ca-menus-list-all',
                    path: '/ca/menus',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__menus__list__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/menus/list/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/menus/list'),
                        })
                      : require('../ca/menus/list').default,
                    exact: true,
                  },
                  {
                    name: 'ca-menus-view-single',
                    path: '/ca/menus/view/:id',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__menus__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/menus/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/menus/view/$id'),
                        })
                      : require('../ca/menus/view/$id').default,
                    exact: true,
                  },
                  {
                    path: '/ca/menus/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                name: 'ca-events',
                path: '/ca/events',
                routes: [
                  {
                    name: 'ca-events-list-all',
                    path: '/ca/events',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__events__list__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/events/list/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/events/list'),
                        })
                      : require('../ca/events/list').default,
                    exact: true,
                  },
                  {
                    name: 'ca-events-view-single',
                    path: '/ca/events/view/:id',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__events__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/events/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/events/view/$id'),
                        })
                      : require('../ca/events/view/$id').default,
                    exact: true,
                  },
                  {
                    path: '/ca/events/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                name: 'ca-deals',
                path: '/ca/deals',
                routes: [
                  {
                    name: 'ca-deals-list-all',
                    path: '/ca/deals',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__deals__list__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/deals/list/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/deals/list'),
                        })
                      : require('../ca/deals/list').default,
                    exact: true,
                  },
                  {
                    name: 'ca-deals-view-single',
                    path: '/ca/deals/view/:id',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__ca__deals__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/deals/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/deals/view/$id'),
                        })
                      : require('../ca/deals/view/$id').default,
                    exact: true,
                  },
                  {
                    path: '/ca/deals/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                name: 'ca-restaurant-details',
                path: '/ca/restaurant-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ca__restaurant-details__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/restaurant-details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/restaurant-details'),
                    })
                  : require('../ca/restaurant-details').default,
                exact: true,
              },
              {
                name: 'ca-home',
                path: '/ca/home',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ca__home__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/ca/home/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__CaLayout" */ '../ca/home'),
                    })
                  : require('../ca/home').default,
                pageIndex: 'caHomePath',
                exact: true,
              },
              {
                path: '/ca',
                redirect: '/ca/home',
                pageIndex: 'caHomeRedirect',
                exact: true,
              },
              {
                path: '/ca/**',
                redirect: '/404',
                exact: true,
              },
            ],
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__AuthLayout" */ '../../layouts/AuthLayout'),
            })
          : require('../../layouts/AuthLayout').default,
        routes: [
          {
            path: '/landing',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__LandingLayout" */ '../../layouts/LandingLayout'),
                })
              : require('../../layouts/LandingLayout').default,
            routes: [
              {
                path: '/landing',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__landing__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/landing/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__LandingLayout" */ '../landing'),
                    })
                  : require('../landing').default,
                exact: true,
              },
              {
                path: '/landing/**',
                redirect: '/404',
                exact: true,
              },
            ],
          },
          {
            path: '/user',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
                })
              : require('../../layouts/UserLayout').default,
            routes: [
              {
                path: '/user/package',
                Routes: [require('../Authorized').default],
                authority: ['owner', 'admin'],
                routes: [
                  {
                    name: 'user-package-view',
                    path: '/user/package/view',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__UserLayout" */ '../user/package/view'),
                        })
                      : require('../user/package/view').default,
                    authority: ['owner', 'admin'],
                    exact: true,
                  },
                  {
                    name: 'user-package-subscription',
                    path: '/user/package/select-subscription',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__package__subscription__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/package/subscription/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__UserLayout" */ '../user/package/subscription'),
                        })
                      : require('../user/package/subscription').default,
                    authority: ['owner', 'admin'],
                    exact: true,
                  },
                  {
                    name: 'user-package-checkout',
                    path: '/user/package/checkout',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__package__checkout__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/package/checkout/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__UserLayout" */ '../user/package/checkout'),
                        })
                      : require('../user/package/checkout').default,
                    authority: ['owner', 'admin'],
                    exact: true,
                  },
                  {
                    name: 'user-package-pay',
                    path: '/user/package/pay',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__package__pay__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/package/pay/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__UserLayout" */ '../user/package/pay'),
                        })
                      : require('../user/package/pay').default,
                    authority: ['owner', 'admin'],
                    exact: true,
                  },
                  {
                    path: '/user/package',
                    redirect: '/user/package/view',
                    authority: ['owner', 'admin'],
                    exact: true,
                  },
                  {
                    path: '/user/package/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                name: 'contact',
                path: '/user/contact',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__contact__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/contact/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__UserLayout" */ '../user/contact'),
                    })
                  : require('../user/contact').default,
                exact: true,
              },
              {
                name: 'login',
                path: '/user/login',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__login__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/login/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__UserLayout" */ '../user/login'),
                    })
                  : require('../user/login').default,
                exact: true,
              },
              {
                name: 'register',
                path: '/user/register',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__register__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/register/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__UserLayout" */ '../user/register'),
                    })
                  : require('../user/register').default,
                exact: true,
              },
              {
                name: 'forget',
                path: '/user/forget-password',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__forget__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/forget/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__UserLayout" */ '../user/forget'),
                    })
                  : require('../user/forget').default,
                exact: true,
              },
              {
                name: 'reset-password',
                path: '/user/reset-password',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__contact__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/contact/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__UserLayout" */ '../user/contact'),
                    })
                  : require('../user/contact').default,
                exact: true,
              },
              {
                name: 'claim',
                path: '/user/claim',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__claim__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/user/claim/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__UserLayout" */ '../user/claim'),
                    })
                  : require('../user/claim').default,
                exact: true,
              },
              {
                path: '/user',
                redirect: '/user/login',
                exact: true,
              },
              {
                path: '/user/**',
                redirect: '/404',
                exact: true,
              },
            ],
          },
          {
            path: '/od',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "layouts__OdLayout" */ '../../layouts/OdLayout'),
                })
              : require('../../layouts/OdLayout').default,
            Routes: [require('../Authorized').default],
            authority: ['owner', 'admin'],
            routes: [
              {
                path: '/od/dashboard',
                icon: 'home',
                name: 'Dashboard',
                level: 'tab_1',
                pageIndex: 'dashboard',
                authority: ['owner', 'admin'],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__od__dashboard__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/dashboard/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__OdLayout" */ '../od/dashboard'),
                    })
                  : require('../od/dashboard').default,
                exact: true,
              },
              {
                path: '/od/management',
                icon: 'profile',
                name: 'Management',
                level: 'tab_1',
                pageIndex: 'management',
                authority: ['owner', 'admin'],
                routes: [
                  {
                    path: '/od/management/dishes',
                    name: 'Dishes',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__dishes__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/dishes/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/dishes/view'),
                        })
                      : require('../od/management/dishes/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/management/dishes/edit/:id',
                    name: 'Dish-Detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__dishes__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/dishes/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/dishes/edit/$id'),
                        })
                      : require('../od/management/dishes/edit/$id').default,
                    authority: ['hide'],
                    exact: true,
                  },
                  {
                    path: '/od/management/dish-types',
                    name: 'Dish-Types',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__dish-types__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/dish-types/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/dish-types/view'),
                        })
                      : require('../od/management/dish-types/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/management/dish-types/edit/:id',
                    name: 'Dish-Types-Detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__dish-types__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/dish-types/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/dish-types/edit/$id'),
                        })
                      : require('../od/management/dish-types/edit/$id').default,
                    authority: ['hide'],
                    exact: true,
                  },
                  {
                    path: '/od/management/category',
                    name: 'Category',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__menus__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/menus/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/menus/view'),
                        })
                      : require('../od/management/menus/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/management/category/edit/:id',
                    name: 'Category-Detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__menus__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/menus/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/menus/edit/$id'),
                        })
                      : require('../od/management/menus/edit/$id').default,
                    authority: ['hide'],
                    exact: true,
                  },
                  {
                    path: '/od/management/modifiers',
                    name: 'Modifiers',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__modifiers__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/modifiers/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/modifiers/view'),
                        })
                      : require('../od/management/modifiers/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/management/modifiers/edit/:id',
                    name: 'Modifier-Detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__modifiers__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/modifiers/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/modifiers/edit/$id'),
                        })
                      : require('../od/management/modifiers/edit/$id').default,
                    authority: ['hide'],
                    exact: true,
                  },
                  {
                    path: '/od/management/modifier-groups',
                    name: 'Modifier-Groups',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__modifications__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/modifications/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/modifications/view'),
                        })
                      : require('../od/management/modifications/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/management/modifier-groups/edit/:id',
                    name: 'Modifier-Groups-Detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__management__modifications__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/management/modifications/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/management/modifications/edit/$id'),
                        })
                      : require('../od/management/modifications/edit/$id')
                          .default,
                    authority: ['hide'],
                    exact: true,
                  },
                  {
                    path: '/od/management',
                    redirect: '/od/management/dishes',
                    exact: true,
                  },
                  {
                    path: '/od/management/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                path: '/od/campaign',
                icon: 'gift',
                name: 'Campaign',
                level: 'tab_1',
                pageIndex: 'campaign',
                routes: [
                  {
                    path: '/od/campaign/events',
                    name: 'Events',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__campaign__events__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/campaign/events/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/campaign/events/view'),
                        })
                      : require('../od/campaign/events/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/campaign/events/edit/:id',
                    name: 'Event-detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__campaign__events__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/campaign/events/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/campaign/events/edit/$id'),
                        })
                      : require('../od/campaign/events/edit/$id').default,
                    authority: ['hide'],
                    exact: true,
                  },
                  {
                    path: '/od/campaign/deals',
                    name: 'Deals',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__campaign__deals__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/campaign/deals/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/campaign/deals/view'),
                        })
                      : require('../od/campaign/deals/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/campaign/deals/edit/:id',
                    name: 'Deal-detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__campaign__deals__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/campaign/deals/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/campaign/deals/edit/$id'),
                        })
                      : require('../od/campaign/deals/edit/$id').default,
                    authority: ['hide'],
                    exact: true,
                  },
                  {
                    path: '/od/campaign',
                    redirect: '/od/campaign/events',
                    exact: true,
                  },
                  {
                    path: '/od/campaign/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                path: '/od/marketing',
                pageIndex: 'marketing',
                icon: 'tag',
                name: 'Marketing',
                level: 'tab_1',
                authority: ['owner', 'admin'],
                routes: [
                  {
                    path: '/od/marketing/coupons',
                    name: 'Coupons',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__marketing__coupons__view__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/marketing/coupons/view/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/marketing/coupons/view'),
                        })
                      : require('../od/marketing/coupons/view').default,
                    exact: true,
                  },
                  {
                    path: '/od/marketing/coupons/edit/:id',
                    name: 'Marketing-Coupon',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__marketing__coupons__edit__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/marketing/coupons/edit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/marketing/coupons/edit/$id'),
                        })
                      : require('../od/marketing/coupons/edit/$id').default,
                    authority: ['hide'],
                    exact: true,
                  },
                ],
              },
              {
                pageIndex: 'orders',
                path: '/od/orders',
                icon: 'shopping-cart',
                name: 'Orders',
                level: 'tab_1',
                authority: ['owner', 'admin'],
                routes: [
                  {
                    pageIndex: 'orders-process',
                    path: '/od/orders/order-processing',
                    name: 'Order-Processing',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__orders__order-processing__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/orders/order-processing/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/orders/order-processing'),
                        })
                      : require('../od/orders/order-processing').default,
                    exact: true,
                  },
                  {
                    pageIndex: 'orders-bill',
                    path: '/od/orders/bill-process',
                    name: 'Bill-Process',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__orders__bill__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/orders/bill/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/orders/bill'),
                        })
                      : require('../od/orders/bill').default,
                    exact: true,
                  },
                  {
                    pageIndex: 'orders-invoice',
                    path: '/od/orders/invoice-center',
                    name: 'Invoice-Center',
                    level: 'sub_tab_2',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__orders__invoice__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/orders/invoice/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/orders/invoice'),
                        })
                      : require('../od/orders/invoice').default,
                    exact: true,
                  },
                  {
                    path: '/od/orders',
                    redirect: '/od/orders/order-processing',
                    exact: true,
                  },
                  {
                    path: '/od/orders/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                path: '/od/analytics',
                icon: 'project',
                name: 'Analytics',
                level: 'tab_1',
                pageIndex: 'analytics',
                authority: ['owner', 'admin'],
                routes: [
                  {
                    path: '/od/analytics/sales',
                    name: 'Analytics-Sales',
                    level: 'sub_tab_2',
                    pageIndex: 'analytics-sales',
                    routes: [
                      {
                        path: '/od/analytics/sales/summary',
                        name: 'Analytics-Sales-Summary',
                        level: 'sub_tab_3',
                        pageIndex: 'analytics-sales-summary',
                        component: __IS_BROWSER
                          ? _dvaDynamic({
                              app: require('@tmp/dva').getApp(),
                              models: () => [
                                import(/* webpackChunkName: 'p__od__analytics__sales__summary__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/analytics/sales/summary/model.ts').then(
                                  m => {
                                    return { namespace: 'model', ...m.default };
                                  },
                                ),
                              ],
                              component: () =>
                                import(/* webpackChunkName: "layouts__OdLayout" */ '../od/analytics/sales/summary'),
                            })
                          : require('../od/analytics/sales/summary').default,
                        exact: true,
                      },
                      {
                        path: '/od/analytics/sales/dishes',
                        name: 'Analytics-Sales-Dishes',
                        level: 'sub_tab_3',
                        pageIndex: 'analytics-sales-dishes',
                        component: __IS_BROWSER
                          ? _dvaDynamic({
                              component: () =>
                                import(/* webpackChunkName: "layouts__OdLayout" */ '../od/analytics/sales/dishes'),
                            })
                          : require('../od/analytics/sales/dishes').default,
                        exact: true,
                      },
                      {
                        path: '/od/analytics/sales/dish-browses',
                        name: 'Analytics-Sales-DishBrowses',
                        level: 'sub_tab_3',
                        pageIndex: 'analytics-sales-dishbrowses',
                        component: __IS_BROWSER
                          ? _dvaDynamic({
                              component: () =>
                                import(/* webpackChunkName: "layouts__OdLayout" */ '../od/analytics/sales/dish-browses'),
                            })
                          : require('../od/analytics/sales/dish-browses')
                              .default,
                        exact: true,
                      },
                      {
                        path: '/od/analytics/sales',
                        redirect: '/od/analytics/sales/summary',
                        exact: true,
                      },
                      {
                        path: '/od/analytics/sales/**',
                        redirect: '/404',
                        exact: true,
                      },
                    ],
                  },
                  {
                    path: '/od/analytics/visitor',
                    name: 'Analytics-Visitors',
                    level: 'sub_tab_2',
                    pageIndex: 'analytics-visitors',
                    routes: [
                      {
                        path: '/od/analytics/visitor/access-summary',
                        name: 'Analytics-Visitors-AccessSummary',
                        level: 'sub_tab_3',
                        pageIndex: 'analytics-visitor-accesssummary',
                        component: __IS_BROWSER
                          ? _dvaDynamic({
                              component: () =>
                                import(/* webpackChunkName: "layouts__OdLayout" */ '../od/analytics/visitor/access-summary'),
                            })
                          : require('../od/analytics/visitor/access-summary')
                              .default,
                        exact: true,
                      },
                      {
                        path: '/od/analytics/visitor',
                        redirect: '/od/analytics/visitor/access-summary',
                        exact: true,
                      },
                      {
                        path: '/od/analytics/visitor/**',
                        redirect: '/404',
                        exact: true,
                      },
                    ],
                  },
                  {
                    path: '/od/analytics',
                    redirect: '/od/analytics/sales',
                    exact: true,
                  },
                  {
                    path: '/od/analytics/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                path: '/od/settings',
                icon: 'setting',
                name: 'Settings',
                level: 'tab_1',
                pageIndex: 'settings',
                authority: ['owner', 'admin'],
                routes: [
                  {
                    path: '/od/settings/personal',
                    name: 'Personal-Profile-Setting',
                    level: 'sub_tab_2',
                    pageIndex: 'settings-person',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__settings__personal__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/settings/personal/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/settings/personal'),
                        })
                      : require('../od/settings/personal').default,
                    exact: true,
                  },
                  {
                    path: '/od/settings/business',
                    name: 'Business-Profile-Setting',
                    level: 'sub_tab_2',
                    pageIndex: 'settings-business',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__settings__business__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/settings/business/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/settings/business'),
                        })
                      : require('../od/settings/business').default,
                    exact: true,
                  },
                  {
                    path: '/od/settings/print',
                    name: 'Print',
                    level: 'sub_tab_2',
                    pageIndex: 'settings-print',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__od__settings__print__model.ts' */ '/builds/popsup/washoku-front-end/src/pages/od/settings/print/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__OdLayout" */ '../od/settings/print'),
                        })
                      : require('../od/settings/print').default,
                    exact: true,
                  },
                  {
                    path: '/od/settings/**',
                    redirect: '/404',
                    exact: true,
                  },
                ],
              },
              {
                path: '/od/stories',
                icon: 'schedule',
                name: 'stories',
                pageIndex: 'stories',
                level: 'tab_1',
                authority: ['owner', 'admin'],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__OdLayout" */ '../od/timelines'),
                    })
                  : require('../od/timelines').default,
                exact: true,
              },
              {
                path: '/od/tickets',
                icon: 'tags',
                name: 'tickets',
                pageIndex: 'tickets',
                level: 'tab_1',
                authority: ['owner', 'admin'],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__OdLayout" */ '../od/eTickets'),
                    })
                  : require('../od/eTickets').default,
                exact: true,
              },
              {
                path: '/od/reservation',
                icon: 'carry-out',
                name: 'reservation',
                pageIndex: 'reservation',
                level: 'tab_1',
                authority: ['owner', 'admin'],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__OdLayout" */ '../od/reservation'),
                    })
                  : require('../od/reservation').default,
                exact: true,
              },
              {
                path: '/od/features',
                icon: 'file',
                name: 'features',
                pageIndex: 'features',
                level: 'tab_1',
                authority: ['owner', 'admin'],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__OdLayout" */ '../od/newFeatures'),
                    })
                  : require('../od/newFeatures').default,
                exact: true,
              },
              {
                path: '/od/support',
                icon: 'team',
                name: 'support',
                pageIndex: 'support',
                level: 'tab_1',
                authority: ['owner', 'admin'],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__OdLayout" */ '../od/support'),
                    })
                  : require('../od/support').default,
                exact: true,
              },
              {
                path: '/od',
                Routes: [require('../Authorized').default],
                authority: ['admin', 'owner'],
                redirect: '/od/dashboard',
                exact: true,
              },
              {
                path: '/od/**',
                redirect: '/404',
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: '/',
        redirect: '/od',
        exact: true,
      },
      {
        path: '/**',
        redirect: '/404',
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
            })
          : require('../404').default,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
