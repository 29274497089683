export default {
  'root.dashboard': 'Tablero',
  'root.management': 'Gestión de menús',
  'root.products': 'Plato',
  'root.product-types': 'Dish Types',
  'root.category': 'Categoría',
  'root.campaign': 'Campaña',
  'root.events': 'Eventos',
  'root.deals': 'ofertas',
  'root.orders': 'Gestión de pedidos',
  'root.bill-process': 'Proceso de Bill',
  'root.order-processing': 'Procesando orden',
  'root.invoice-center': 'factura Centro',
  'root.settings': 'ajustes',
  'root.personal': 'Perfil Personal Ajuste',
  'root.business': 'Perfil de la empresa Configuración',
  'root.package': 'Paquete',
  'root.print': 'Configuración de la impresora',
  'root.premium-services': 'Servicios especiales',
  'root.modifiers': 'Modificadora',
  'root.modifier-groups': 'Grupos de modificadores',
  'root.coupon': 'Cupón',
  'root.coupons': 'Cupones',
  'root.summary': 'resumen',
  'root.product-browses': 'Plato navega',
  'root.access-summary': 'Resumen de acceso',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': 'E-ticket',
  'root.reservation': 'Reserva',
  'root.services': 'Servicios profesionales',

  'root.stories': 'Historias',
  'root.support': 'Soporte',
  'root.features': 'Nuevas funciones',
};
