'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = {
  // Options.jsx
  items_per_page: '/ ページ',
  jump_to: '移動',
  jump_to_confirm: '確認する',
  page: 'ページ',

  // Pagination.jsx
  prev_page: '前のページ',
  next_page: '次のページ',
  prev_5: '前 5ページ',
  next_5: '次 5ページ',
  prev_3: '前 3ページ',
  next_3: '次 3ページ'
};
module.exports = exports['default'];