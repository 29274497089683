import React from 'react';
import { message } from 'antd';
import Redirect from 'umi/redirect';
import { connect } from 'dva';
import { getMapStateToProps } from '@/utils/utils';
import { ConnectProps, ConnectState, UserModelState } from '@/models/connect';

const CaStaffAuthGate: React.FC<any> = ({
  children,
  ca,
}) => {
  const { viewerType } = ca;

  if (viewerType === 'staff') {
    return <Redirect to='/ca/cart' />;
  }

  return children;
};

export default connect(getMapStateToProps(['ca']))(CaStaffAuthGate);
