export interface OdSettings {
  title: string;
  collapsed: boolean;

  editingBusiness: any; //TBD.
  editingBusinessDetails: any;
  editingLang: string;

  allDishes: any[];
  allMenus: any[];
  allModifierGroups: any[];

  tagList: {
    ingredient: any;
    business: any;
  };
  offeringTypeList: any;

  translations: {
    [key: string]: any;
  };

  ossSettings?: {
    ossTokens: {
      region: string;
      bucket: string;
      accessKeyId: string;
      accessKeySecret: string;
      stsToken: string;
    };
    callBack: {
      callbackUrl: string;
      callbackBody: string;
      callbackBodyType: string;
    };
    customValue: {
      [key: string]: any;
    };
    expiration: number;
  };
  ossFilePaths?: {
    userAvator: string;
    businessProfile: string;
    businessPdfMenu: string;
    businessDish: string;
    businessMenu: string;
    businessEvent: string;
    businessDeal: string;
  };

  reloadAction: {
    showActioningMessage: 'unset' | boolean;
    spaceName: string;
    redirectUrl?: string;
    reloadType?: string;
    pageModelKey?: string;
  };

  showPreview: boolean;

  previewPageData: {
    moduleKey: string;
    data: any;
  };

  odModuleLoaded: {
    api: {
      trans: boolean;

      tag: {
        tagList: boolean;
        offeringTypeList: boolean;
      };
      buisinessDetails: boolean;
      allDishes: boolean;
      allMenus: boolean;
      allModifierGroups: boolean;

      odDishes: {
        self: boolean;
      };

      odDish: {
        self: boolean;
      };

      odDishTypes: {
        self: boolean;
      };

      odDishType: {
        self: boolean;
      };

      odMenus: {
        self: boolean;
      };

      odMenu: {
        self: boolean;
      };
      odCoupons: {
        self: boolean;
      };

      odCoupon: {
        self: boolean;
      };

      odModifiers: {
        self: boolean;
      };
      odModifier: {
        self: boolean;
      };
      odModifications: {
        self: boolean;
      };
      odModification: {
        self: boolean;
        extra: boolean;
      };

      odEvents: { self: boolean };

      odEvent: { self: boolean };

      odDeals: { self: boolean };

      odDeal: {
        self: boolean;
      };

      odSettingBusiness: {
        self: boolean;
      };
      odSettingPrint: {
        self: boolean;
      };

      odDashboard: {
        self: boolean;
      };

      odOrderProcessing: {
        self: boolean;
      };

      odOrderBill: {
        self: boolean;
      };

      odOrderInvoice: {
        self: boolean;
      };

      odAnalyticsSalesSummary: {
        self: boolean;
      };
    };

    odDashboard: boolean;
    //Management相关
    odDishes: boolean;
    odDish: boolean;
    odDishTypes: boolean;
    odDishType: boolean;
    odMenus: boolean;
    odMenu: boolean;
    odModifiers: boolean;
    odModifier: boolean;
    odModifications: boolean;
    odModification: boolean;

    odCoupons: boolean;
    odCoupon: boolean;
    odEvents: boolean;
    odEvent: boolean;
    odDeals: boolean;
    odDeal: boolean;
    odSettingBusiness: boolean;
    odSettingPrint: boolean;
    odOrderProcessing: boolean;
    odOrderBill: boolean;
    odOrderInvoice: boolean;

    odAnalyticsSalesSummary: boolean;
  };
}

export default {
  title: 'Hospitality Digital Solutions',
  collapsed: false,
  translations: {},
  reloadAction: {
    showActioningMessage: 'unset',
  },
  showPreview: false,

  odModuleLoaded: {
    api: {
      trans: false,

      tag: {
        tagList: false,
        offeringTypeList: false,
      },
      buisinessDetails: false,
      allDishes: false,
      allMenus: false, //allDishes
      allModifierGroups: false,

      odDashboard: {
        self: false,
      },

      odDishes: {
        self: false,
      },

      odDish: {
        self: false,
      },

      odDishTypes: {
        self: false,
      },

      odDishType: {
        self: false,
      },

      odMenus: {
        self: false,
      },

      odMenu: {
        self: false, //allDishes
      },
      odCoupons: {
        self: false,
      },

      odCoupon: {
        self: false,
      },

      odModifiers: {
        self: false,
      },
      odModifier: {
        self: false,
      },
      odModifications: {
        self: false,
      },
      odModification: {
        self: false,
        extra: false,
      },

      odEvents: { self: false },

      odEvent: { self: false },
      odDeals: { self: false },

      odDeal: {
        self: false, //allDishes
      },

      odSettingBusiness: {
        self: false,
      },
      odSettingPrint: {
        self: false,
      },

      odOrderProcessing: {
        self: false,
      },

      odOrderBill: {
        self: false,
      },

      odOrderInvoice: {
        self: false,
      },
      odAnalyticsSalesSummary: {
        self: false,
      },
    },

    odDashboard: false,
    //Management相关
    odDishes: false,
    odDish: false,
    odDishTypes: false,
    odDishType: false,
    odMenus: false,
    odMenu: false,
    odModifiers: false,
    odModifier: false,
    odModifications: false,
    odModification: false,

    odCoupons: false,
    odCoupon: false,
    odEvents: false,
    odEvent: false,
    odDeals: false,
    odDeal: false,
    odSettingBusiness: false,
    odSettingPrint: false,
    odOrderProcessing: false,
    odOrderBill: false,
    odOrderInvoice: false,

    odAnalyticsSalesSummary: false,
  },
} as OdSettings;
