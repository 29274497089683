import { message } from 'antd';
import { Effect, Subscription } from 'dva';
import { Reducer } from 'redux';
import translationSettings, { TranslationSettings } from '../../config/translationSettings';
import { getPageQuery } from '@/utils/utils';
import { readUserInfoFromStorage, removeUserInfoFromStorage } from '@/utils/user';
import { stringify, parse } from 'querystring';
import { getWelcomeInfoDetail } from '@/services/welcome';
import { setAuthorizedUserInfo } from '@/utils/authority';
import { router } from 'umi';
import { ConnectState } from '@/models/connect';
import { getLoadedModuleStatus } from '@/utils/metaModules';
import _ from 'lodash';
import { metaTranslationOfTranslationGroups } from '@/constants/translationGroups';
import queryString from 'query-string';

export interface ModelType {
  namespace: 'translation';
  state: TranslationSettings;
  effects: {
    fetchMeta: Effect;
  };
  reducers: {};
  subscriptions: {};
}

const UserModel: ModelType = {
  namespace: 'translation',
  state: translationSettings,
  effects: {
    *fetchMeta({ reload = false }, { put, select, all }) {
      //如果reload为true则先清空所有的trans
      if (reload) {
        yield put({
          type: 'meta/setLoadedModule',
          reload,
        });
      }
      /*
        1. transPkg
        2. claimInfo
       */
      const { requiredLang, metaModuleLoaded } = yield select((state: ConnectState) => ({
        requiredLang: state.meta.viewingLang,
        metaModuleLoaded: state.meta.metaModuleLoaded,
      }));

      const transGroups = metaTranslationOfTranslationGroups;

      const payload = { requiredLang, transGroups };

      let getActions = getLoadedModuleStatus('translation', metaModuleLoaded, payload);

      if (getActions) {
        let actionArray: any = [];

        _.forEach(getActions, (value: any) => actionArray.push(put(value)));
        yield all(actionArray);
      }
    },
  },
  reducers: {},

  subscriptions: {},
};

export default UserModel;
