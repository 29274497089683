export default {
  'root.dashboard': '仪盘表',
  'root.management': '管理',
  'root.products': '菜肴',
  'root.product-types': 'Dish Types',
  'root.category': '菜单',
  'root.campaign': '活动',
  'root.events': '事件',
  'root.deals': '优惠',
  'root.orders': '订单',
  'root.bill-process': '账单支付',
  'root.order-processing': '订单处理',
  'root.invoice-center': '收据中心',
  'root.settings': '设置',
  'root.personal': '个人档案设置',
  'root.business': '生意档案设置',
  'root.package': '套餐',
  'root.print': '打印机设置',
  'root.premium-services': '高级服务',
  'root.modifiers': '修饰符',
  'root.modifier-groups': '修饰符组',
  'root.coupon': '优惠券',
  'root.coupons': '优惠券',
  'root.summary': '总结',
  'root.product-browses': '菜品浏览',
  'root.access-summary': '访问摘要',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': '电子票务',
  'root.reservation': '预订',
  'root.services': '高级服务',

  'root.stories': '公告',
  'root.features': '新功能',
  'root.support': '客服',
};
