/**
 * set loaded modules status
 * @param meta.metaModuleLoaded [claimInfo, userDetails, cities, transPkg: {}]
 * @param meta.metaModuleLoaded [claimInfo, userDetails, cities, transPkg: {}]
 */
export const setLoadedModuleStatus = (modules: any) => {
  modules.ca =
    modules.api.currentCartStatus &&
    modules.api.businessConfigs &&
    modules.api.caAdvertise &&
    modules.api.transPkg.ca
      ? true
      : false;
  modules.user =
    modules.api.claimInfo &&
    modules.api.userDetails &&
    modules.api.currentEditingBusinessPackages &&
    modules.api.transPkg.user
      ? true
      : false;
  modules.landing =
    modules.api.claimInfo &&
    modules.api.userDetails &&
    modules.api.currentEditingBusinessPackages &&
    modules.api.transPkg.landing
      ? true
      : false;
  modules.od =
    modules.api.userDetails && modules.api.currentEditingBusinessPackages && modules.api.transPkg.od
      ? true
      : false;
  modules.translation = modules.api.transPkg.translation ? true : false;

  return modules;
};

export const reloadModuleStatus = (reload: any, currentLoadedModule: any) => {
  const blankApi = {
    caAdvertise: false,
    currentCartStatus: false, //ca
    currentEditingBusinessPackages: false,
    businessConfigs: false,
    claimInfo: false,
    userDetails: false,
    transPkg: {
      ca: false,
      od: false,
      landing: false,
      user: false,
      translation: false,
    },
  };

  if (reload === 'all') {
    //all --  全都改
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...blankApi,
      },
    };
  } else if (reload === 'trans') {
    //trans --  全都改 (display除外)
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...blankApi,
        businessConfigs: currentLoadedModule.api.businessConfigs,
      },
    };
  } else if (reload === 'room-status') {
    // ca -- room - pin 只改自己
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...currentLoadedModule.api,
        currentCartStatus: false,
      },
    };
  } else if (reload === 'user-status') {
    currentLoadedModule = {
      //user -- status 次级依赖问题
      ...currentLoadedModule,
      api: {
        ...currentLoadedModule.api,
        userDetails: false,
        currentEditingBusinessPackages: false,
      },
    };
  }

  return currentLoadedModule;
};

/**
 *
 * @param moduleKey indicates which module you want to check
 * @param metaModuleLoaded current metaModuleLoaded model
 * @param state ConnectState data - object contains meta data
 *
 * Possible Arguments in "state"
 *  Landing - claimBasic, userId
 *  Od - userId
 *  User - claimBasic
 *
 *  Other (Translation related)
 *   requiredLange
 *   transGroups
 */

export const getLoadedModuleStatus = (moduleKey: string, metaModuleLoaded: any, state: any) => {
  const {
    currentCart = false,
    claimBasic = false,
    userId = false,
    requiredLang = false,
    transGroups = false,
  } = state;

  let actions = [];

  /**
   * 先检查哪一个模块需要取meta
   * 如果状态机里的值（eg metaModuleLoaded.claimInfo）是 false
   * 并且它对应的数据（eg claimBasic）都存在的话 则调用fetchClaimInfo 去获取需要的数据
   *
   * 否则需要setLoadedModule 更改状态机里的值
   */

  if (moduleKey && requiredLang && transGroups && !metaModuleLoaded.api.transPkg[moduleKey]) {
    //trans里面没有load 就load
    //load过了就不用再load了
    actions.push({
      type: 'meta/fetchTranslations',
      requiredLang,
      transGroups,
      baseTans: moduleKey,
    });
  }

  if (moduleKey === 'ca') {
    if (!metaModuleLoaded.api.caAdvertise) {
      actions.push({
        type: 'fetchCaAdvertise',
      });
    }
    if (!metaModuleLoaded.api.businessConfigs) {
      actions.push({
        type: 'fetchCurrentBusinessConfigs',
      });
    }

    if (!metaModuleLoaded.api.currentCartStatus && currentCart) {
      actions.push({
        type: 'fetchCurrentCartStatus',
        currentCart,
      });
    } else {
      actions.push({
        type: 'meta/setLoadedModule',
        key: 'currentCartStatus',
        value: true,
      });
    }
  }

  if (['landing', 'user'].includes(moduleKey)) {
    if (!metaModuleLoaded.api.claimInfo && claimBasic) {
      actions.push({
        type: 'meta/fetchClaimInfo',
        claimBusinessId: claimBasic.businessId,
        claimToken: claimBasic.token,
      });
    } else {
      actions.push({
        type: 'meta/setLoadedModule',
        key: 'claimInfo',
        value: true,
      });
    }
  }

  if (['landing', 'user', 'od'].includes(moduleKey)) {
    if (!metaModuleLoaded.api.userDetails && userId) {
      actions.push({
        type: 'meta/fetchCurrentUserDetails',
        userId: userId,
      });
    } else {
      actions.push({
        type: 'meta/setLoadedModule',
        key: 'userDetails',
        value: true,
      });
      //解决次级依赖的问题
      actions.push({
        type: 'meta/setLoadedModule',
        key: 'currentEditingBusinessPackages',
        value: true,
      });
    }
  }
  return actions.length === 0 ? false : actions;
};
