import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import Redirect from 'umi/redirect';
import { connect } from 'dva';
import { getMapStateToProps } from '@/utils/utils';
import { ConnectProps, ConnectState, UserModelState } from '@/models/connect';
import PageLoading from '@/components/PageLoading';

const caProcessOrderPath = '/ca/order/process';
const caInvoiceOrderPath = '/ca/order/invoice';
const validCaCartCheckOutResult = ['success', 'abort']; //做一个参考

const CaCartStatusGate: React.FC<any> = ({
  children,
  ca,
  dispatch,
  loading,
  location,
  caOrderProcess = false,
  caOrderInvoice = false,
}) => {
  const { currentCartStatus, currentCart, cartCheckoutResult } = ca;
  const { confirmStatus = false } = caOrderProcess;
  const { stripeInvoice = false } = caOrderInvoice;

  const isCurrentPathInvoice = location.pathname === caInvoiceOrderPath ? true : false;
  const isCurrentPathProcess = location.pathname === caProcessOrderPath ? true : false;

  useEffect(() => {
    //没有cart就不用查了 直接return
    if (!currentCart) {
      return;
    }

    //如果现在要看process页面 并且支付的结果是abort
    //则说明现在这个情况是从cancel url过来的
    //这里发起一个请求去把之前的stripe session给cencel掉
    if (isCurrentPathProcess && cartCheckoutResult.status === 'abort') {
      dispatch({
        type: `ca/deleteStripeSession`,
      });
    }

    //更新这个cart的状态
    dispatch({
      type: `ca/fetchCurrentCartStatus`,
      currentCart,
    });
  }, []);

  //TODO 这里因为要保留form里面填的东西所以暂时hide
  //如果此时是正在更新状态或者删除session
  // const showLoading =
  //   loading.effects['ca/fetchCurrentCartStatus'] || loading.effects['ca/deleteStripeSession'];
  // if (showLoading) {
  //   return <PageLoading />;
  // }

  //这里有一个预处理 就是现在是支付成功了要看invoice的情况
  //这里如果现在是在看invoice 但是没有准入许可是不行的
  //这里目前暂定只有当结账成功的时候才能看invoice
  if (isCurrentPathInvoice && cartCheckoutResult.status !== 'success') {
    return <Redirect to="/ca/home" />;
  }

  /**
   * @@参数说明
   * 如果当前id不存在，跳转到home页: 没有cart或者cart的状态不对的时候就跳转
   *
   * 在普通情况下unconfirmed的状态视为valid状态 只有这个状态才能在caOrderProcess继续处理
   * 相反在caOrderInvoice里面unconfirm则不能处理 -- 只有confirm才能处理
   */

  const isCurrentCartEmpty = currentCart && currentCart.cartId ? false : true;

  const isCurrentCartStatusUnconfirmed =
    currentCartStatus && currentCartStatus.status === 'unconfirmed' ? true : false;

  /**
   * caOrderProcess校验
   * 如果是路由为caOrderProcess的话 需要看状态
   * 首先归纳一下所有的可能
   *
   * caOrderProcess 可能为普通状态或者已经submitConfirm之后的状态
   * 如果是submitConfirm之后 那么在caOrderProcess里的confirmStatus会变成confirmed
   *  **/

  //先校验caOrderProcess的特殊情况
  const isCaOrderProcessConfirmStatusConfirmed =
    confirmStatus && confirmStatus === 'confirmed' ? true : false;

  if (isCurrentPathProcess && isCaOrderProcessConfirmStatusConfirmed) {
    return children;
  }

  //再校验caOrderProcess普通情况 -- empty不能进
  if (isCurrentPathProcess && isCurrentCartEmpty) {
    return <Redirect to="/ca/home" />;
  }
  //caOrderProcess普通情况 -- 状态不对也不能进
  if (isCurrentPathProcess && !isCurrentCartStatusUnconfirmed) {
    return <Redirect to="/ca/home" />;
  }

  /**
   *
   * caOrderInvoice校验
   * 如果是路由为caOrderInvoice的话 需要看状态
   *
   * 首先归纳一下所有的可能
   * caOrderInvoice 可能为取到stripeInvoice之前的状态或之后的状态
   *
   * 在取到stripeInvoice之前必须有currentCart
   *
   * **/

  //先校验caOrderInvoice的特殊情况
  if (isCurrentPathInvoice && stripeInvoice) {
    return children;
  }
  //再校验caOrderInvoice的普通情况 -- empty不能进
  if (isCurrentPathInvoice && isCurrentCartEmpty) {
    return <Redirect to="/ca/home" />;
  }

  return children;
};

export default connect(getMapStateToProps(['ca', 'loading', 'caOrderProcess', 'caOrderInvoice']))(
  CaCartStatusGate,
);
