import _ from 'lodash';
import { isAllKeyTrue } from '@/utils/utils';

/**
 * set loaded ca status
 * @param ca.caModuleLoaded
 * @param ca.caModuleLoaded
 */

export const setLoadedModuleStatus = (modules: any) => {
  modules.caDishes = isAllKeyTrue(modules.api.caDishes) && modules.api.tag.tagList ? true : false;
  modules.caDish = isAllKeyTrue(modules.api.caDish) ? true : false;

  modules.caMenus = isAllKeyTrue(modules.api.caMenus) ? true : false;
  modules.caMenu =
    isAllKeyTrue(modules.api.caMenu) && modules.api.tag.tagList && modules.api.allMenus
      ? true
      : false;

  modules.caEvents = isAllKeyTrue(modules.api.caEvents) ? true : false;
  modules.caEvent = isAllKeyTrue(modules.api.caEvent) ? true : false;

  modules.caDeals = isAllKeyTrue(modules.api.caDeals) ? true : false;
  modules.caDeal = isAllKeyTrue(modules.api.caDeal) ? true : false;

  modules.caHome = isAllKeyTrue(modules.api.caHome) ? true : false;
  modules.caInfo = isAllKeyTrue(modules.api.caInfo) ? true : false;
  modules.caCart = isAllKeyTrue(modules.api.caCart) ? true : false;
  modules.caPreviousOrder = isAllKeyTrue(modules.api.caPreviousOrder) ? true : false;

  modules.caOrderProcess = isAllKeyTrue(modules.api.caOrderProcess) ? true : false;
  modules.caOrderPayment = isAllKeyTrue(modules.api.caOrderPayment) ? true : false;

  //TBD. caOrderInvoice
  return modules;
};

/**
 *
 * @param reload trans, business, dish-update
 * @param currentLoadedModule current odModuleLoaded model
 *
 */
export const reloadModuleStatus = (reload: any, currentLoadedModule: any) => {
  const blankApi = {
    caDishes: { self: false },
    caDish: { self: false },
    caMenus: { self: false },
    caMenu: { self: false },
    caEvents: { self: false },
    caEvent: { self: false },
    caDeals: { self: false },
    caDeal: { self: false },

    caHome: {
      recommendedDishes: false,
      recommendedMenus: false,
      recommendedEvents: false,
      recommendedDeals: false,
    },
    caInfo: { self: false },
    caCart: { self: false },
    caPreviousOrder: { self: false },
    caOrderProcess: { self: false },
    caOrderPayment: { self: false },
    caOrderInvoice: { self: false },

    allMenus: false,
    tag: {
      tagList: false,
      offeringTypeList: false,
    },
    staffLogin: false,
  };

  if (reload === 'view-trans' || reload === 'all') {
    //如果是view-trans 则reload所有跟view-trans相关的部分
    //api全换
    currentLoadedModule = {
      ...currentLoadedModule,
      api: blankApi,
    };
  }

  /*
    reload :
        caDish: 'caDish-switch',
        caMenu: 'caMenu-switch',
        caEvent: 'caEvent-switch'',
        caDeal: 'caDeal-switch',

        caDishes: 'caDishes-clear',
        caMenues: 'caMenus-clear',
        caEvent: 'caEvents-clear',
        caDeal: 'caDeals-clear',

        caCart: 'caCart-fresh',

        reloadModule: caDish/caMenu/caEvent/caDeal/caCart caDishes/caMenus/caEvents/caDeals/fresh
        actionMode: switch  clear fresh

    */

  const allModules = [
    'caDishes',
    'caDish',
    'caMenus',
    'caMenu',
    'caEvents',
    'caEvent',
    'caDeals',
    'caDeal',

    'caHome',
    'caInfo',
    'caCart',
    'caOrderProcess',
    'caOrderPayment',
  ];

  const actionArray = ['switch', 'clear', 'fresh'];

  const modelsNeedToReloadCurrentCartWhenFresh = [
    'caCart',
    'caOrderProcess',
    'caOrderPayment',
    'caOrderInvoice',
  ];

  const [reloadModule, actionMode] = reload.split('-');

  if (allModules.includes(reloadModule) && actionArray.includes(actionMode)) {
    //switch | clear 某一项 则它的self要首先更改为false
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...currentLoadedModule.api,
        [reloadModule]: { ...currentLoadedModule.api[reloadModule], self: false },
      },
    };
  }

  //处理需要reload currentCart的情况
  if (actionMode === 'fresh' && modelsNeedToReloadCurrentCartWhenFresh.includes(reloadModule)) {
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...currentLoadedModule.api,
        caCart: { self: false },
        caPreviousOrder: { self: false },
        caOrderProcess: { self: false },
        caOrderPayment: { self: false },
        caOrderInvoice: { self: false },
      },
    };
  }

  return currentLoadedModule;
};

/**
 *
 * @param moduleKey indicates which module you want to check
 * @param caModuleLoaded current caModuleLoaded model
 * @param state ConnectState data - object contains meta data
 *
 * Possible Arguments in "state"
 */

export const getLoadedModuleStatus = (
  moduleKey: string,
  caModuleLoaded: any,
  state = false as any,
) => {
  const { currentCart = false, viewingId = false } = state;

  const allModules = [
    'caDishes',
    'caDish',
    'caMenus',
    'caMenu',
    'caEvents',
    'caEvent',
    'caDeals',
    'caDeal',

    'caHome',
    'caInfo',
    'caCart',
    'caPreviousOrder',
    'caPayment',
    'caOrder',
  ];

  const modelsNeedToSendSelfId = ['caDish', 'caMenu', 'caDeal', 'caEvent'];
  const modelsNoNeedToSendSelfId = [
    'caDishes',
    'caMenus',
    'caDeals',
    'caEvents',
    'caInfo',
    'caPreviousOrder',
  ];

  const modelsNeedToLoadRecommend = ['caHome'];
  const modelsNeedToSendCurrentCart = ['caCart', 'caOrderPayment'];

  const modelsNoNeedToLoadTagList = ['caDishes', 'caMenu'];
  // const modelsNoNeedToLoadOfferingTypeList = ['caMenu'];
  const modelsNoNeedToLoadAllMenus = ['caMenu'];

  let actions = [];

  /**
   * 先检查哪一个模块需要取meta
   * 如果状态机里的值（eg caModuleLoaded.dishe）是 false
   * 并且它对应的数据（eg id）都存在的话 则调用fetch 去获取需要的数据
   *
   * 否则需要setLoadedModule 更改状态机里的值
   */

  const capitalizedModuleKey = _.upperFirst(moduleKey);

  //检查有没有load过self, 没有则load
  if (modelsNoNeedToSendSelfId.includes(moduleKey) && !caModuleLoaded.api[moduleKey].self) {
    actions.push({
      type: 'fetch',
    });
  }

  if (
    modelsNeedToSendSelfId.includes(moduleKey) &&
    viewingId &&
    !caModuleLoaded.api[moduleKey].self
  ) {
    actions.push({
      type: 'fetch',
      viewingId,
    });
  }

  //处理caHome
  if (modelsNeedToLoadRecommend.includes(moduleKey)) {
    _.forEach(caModuleLoaded.api[moduleKey], (loaded: boolean, key: string) => {
      if (!loaded) {
        actions.push({
          type: `fetch${capitalizedModuleKey}${_.upperFirst(key)}`, //fetchCaHomeRecommendedDishes ...
        });
      }
    });
  }

  //处理caCart
  if (modelsNeedToSendCurrentCart.includes(moduleKey) && currentCart) {
    if (currentCart.cartId && currentCart.cartType && !caModuleLoaded.api[moduleKey].self) {
      actions.push({
        type: `fetchCurrentCartDetails`,
        currentCart,
      });
    } else if (currentCart === 'needToCreateIndividalOrder') {
      actions.push({
        type: 'ca/createIndividalOrder',
      });
    }
  }

  if (modelsNoNeedToLoadTagList.includes(moduleKey) && !caModuleLoaded.api.tag.tagList) {
    actions.push({
      type: 'ca/fetchTagList',
    });
  }

  // if (
  //   modelsNoNeedToLoadOfferingTypeList.includes(moduleKey) &&
  //   !caModuleLoaded.api.tag.offeringTypeList
  // ) {
  //   actions.push({
  //     type: 'ca/fetchOfferingTypeList',
  //   });
  // }

  if (modelsNoNeedToLoadAllMenus.includes(moduleKey) && !caModuleLoaded.api.allMenus) {
    actions.push({
      type: 'ca/fetchAllMenus',
    });
  }

  return actions.length === 0 ? false : actions;
};
