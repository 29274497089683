export interface LandingSettings {
  actionButtonInfo: {
    actionRoute: string;
    header: {
      actionText: string;
    };
    package: {
      packageText: string;
      packageHeader: string;
    };
  };
}

export default {
  actionButtonInfo: {
    header: {},
    package: {},
  },
} as LandingSettings;
