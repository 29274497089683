export const metaTranslationOfOdGroups = [
  'Global',
  'Dashboard',
  'Package',
  'Management_Dish_Grid_View',
  'Management_Dish_Detail',
  'Management_Dish_Type',
  'Management_Menu_Grid_View',
  'Management_Menu_Detail',
  'Management_Menu_List_View',
  'Management_Modifier',
  'Management_Modification',
  'Marketing',
  'Login',
  'Management_Event',
  'Management_Deal',
  'Premium_Service',
  'Settings_Business',
  'Orders_Payments',
  'CA_Cart',
  'CA_Deals',
  'CA_Menus',
  'Contact_POPSUP',
  'Register',
  'Claim_Business',
  'Orders_Payments',
  'Settings_Business_Profile_Setting',
  'Management_Product_Grid_View',
  'Management_Product_Detail',
  'Management_Product_Type',
];

export const odTranslationGroups = [
  'Login',
  'Global',
  'Marketing',
  'Management_Dish_Detail',
  'Management_Menu_Detail',
  'Management_Dish_Type',
  'Management_Modifier',
  'Management_Event',
  'Management_Deal',
  'Settings_Business_Profile_Setting',
  'Package',
  'Management_Product_Grid_View',
  'Management_Product_Detail',
  'Management_Product_Type',
];

export const metaTranslationOfCaGroups = [
  'Global',
  'CA_Cart',
  'Orders_Payments',
  'Login',
  'CA_Deals',
  'CA_Menus',
  'CA_Events',
  'Management_Event',
  'CA_Group',
  'CA_Home',
  'CA_Info',
  'CA_Welcome',
  'Management_Event',
  'header',
  'Dashboard',
  'Register',
  'Claim_Business',
  'Settings_Business_Profile_Setting',
  'CA_Previous_Order',
  'Marketing',
];

export const metaTranslationOfLandingGroups = [
  'Global',
  'Orders_Payments',
  'Login',
  'Dashboard',
  'Register',
  'Claim_Business',
];

export const metaTranslationOfUserGroups = [
  'Global',
  'Orders_Payments',
  'Login',
  'Contact_POPSUP',
  'Dashboard',
  'Settings_Business_Profile_Setting',
  'Register',
  'Claim_Business',
];

export const metaTranslationOfTranslationGroups = ['Global', 'Welcome'];
