export default {
  'menu.Dashboard': 'Tablero',
  'menu.Management': 'Gestión de menús',
  'menu.Management.Dishes': 'Plato',
  'menu.Management.Category': 'Categoría',
  'menu.Campaign': 'Campaña',
  'menu.Campaign.Events': 'Eventos',
  'menu.Campaign.Deals': 'ofertas',
  'menu.Orders': 'Gestión de pedidos',
  'menu.Orders.Bill-Process': 'Proceso de Bill',
  'menu.Orders.Order-Processing': 'Procesando orden',
  'menu.Orders.Invoice-Center': 'factura Centro',
  'menu.Settings': 'ajustes',
  'menu.Settings.Personal-Profile-Setting': 'Perfil Personal Ajuste',
  'menu.Settings.Business-Profile-Setting': 'Perfil de la empresa Configuración',
  'menu.Settings.Print': 'Configuración de la impresora',
  'menu.Package': 'Paquete',
  'menu.Premium-Services': 'Servicios especiales',
  'menu.Management.Dish-Detail': 'Detalle del plato',
  'menu.Management.Dish-Types': 'Dish Types',
  'menu.Management.Dish-Types-Detail': 'Detalle de tipos de platos',
  'menu.Management.Category-Detail': 'Detalle del menú',
  'menu.Management.Modifiers': 'Modificadoras',
  'menu.Management.Modifier-Detail': 'Detalle del modificador',
  'menu.Management.Modifier-Groups': 'Grupos de modificadores',
  'menu.Management.Modifier-Groups-Detail': 'Detalle de grupos de modificadores',
  'menu.Campaign.Event-detail': 'Información sobre eventos',
  'menu.Campaign.Deal-detail': 'Detalle acuerdo',
  'menu.Home': 'Casa',
  'menu.Marketing': 'márketing',
  'menu.Marketing.Coupons': 'Cupones',
  'menu.Marketing.Marketing-Coupon': 'Cupón',
  'menu.Analytics': 'Analítica',
  'menu.Analytics.Analytics-Sales': 'Análisis de ventas',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': 'Resumen de ventas',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': 'Venta de Platos',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': 'Resumen de búsquedas de platos',
  'menu.Analytics.Analytics-Visitors': 'Análisis de visitantes',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': 'Resumen de acceso',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': 'Boletos electrónicos',
  'menu.reservation': 'Reserva',
  'menu.services': 'Servicios avanzados',

  'menu.stories': 'Historias',
  'menu.support': 'Soporte',
  'menu.features': 'Nuevas funciones',
};
