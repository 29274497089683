export const readUserInfoFromStorage = () => {
  let userInfo: any = false;
  if (localStorage && localStorage.getItem('popsup-authorized-user-info')) {
    userInfo = JSON.parse(localStorage.getItem('popsup-authorized-user-info') as string);
  } //先从localStorage里面读

  if (!userInfo) {
    //再从sessionStorage里面读
    if (sessionStorage && sessionStorage.getItem('popsup-authorized-user-info')) {
      userInfo = JSON.parse(sessionStorage.getItem('popsup-authorized-user-info') as string);
    }
  }
  return userInfo;
};

export const removeUserInfoFromStorage = () => {
  if (localStorage && localStorage.getItem('popsup-authorized-user-info')) {
    localStorage.removeItem('popsup-authorized-user-info');
  }
  if (sessionStorage && sessionStorage.getItem('popsup-authorized-user-info')) {
    sessionStorage.removeItem('popsup-authorized-user-info');
  }
};
