import { message } from 'antd';
import { Reducer } from 'redux';
import metaSettings, { MetaSettings } from '../../config/metaSettings';
import {
  getTranslations,
  getClaimBusinessInfo,
  getCurrentUserdetails,
  getCurrentEditingBusinessPackages,
} from '@/services/meta';
import { Subscription, Effect } from 'dva';
import { LANGUAGELABLE, languageToLocal } from '@/constants/Languages';
import { ValidLoginRedirectParams } from '@/constants/RoutesParams';
import { getPageQuery } from '@/utils/utils';
import { setLoadedModuleStatus, reloadModuleStatus } from '@/utils/metaModules';
import { stringify, parse } from 'querystring';
import { ConnectState } from '@/models/connect';
import router from 'umi/router';
import { convertJsonToCamelCase } from '@/utils/utils';
import { setLocale } from 'umi-plugin-react/locale';
import queryString from 'query-string';

export interface MetaModelType {
  namespace: string;
  state: MetaSettings;
  effects: {
    fetchCurrentUserDetails: Effect;
    fetchTranslations: Effect;
    fetchClaimInfo: Effect;
    fetchCurrentEditingBusinessPackages: Effect;
  };
  reducers: {
    saveTranslations: Reducer<MetaSettings>;
    saveCurrentEditingBusinessPackages: Reducer<MetaSettings>;
    initClaimInfo: Reducer<MetaSettings>;
    setViewingLang: Reducer<MetaSettings>;
    setLoadedModule: Reducer<MetaSettings>;
    saveClaimInfo: Reducer<MetaSettings>;
    setClaimSteps: Reducer<MetaSettings>;
    saveCurrentUserDetails: Reducer<MetaSettings>;
    setMobileType: Reducer<MetaSettings>;
    setLoginRedirect: Reducer<MetaSettings>;
  };
  subscriptions: {
    metaInit: Subscription;
  };
}

const MetaModel: MetaModelType = {
  namespace: 'meta',
  state: metaSettings,
  effects: {
    *fetchCurrentUserDetails({ userId }, { call, put }) {
      const response = yield call(getCurrentUserdetails, { userId });
      if (response.success) {
        let defaultActiveBusiness: any = false;

        const ownedBusiness = response.result.business.map((x: any) => {
          if (x.default) {
            defaultActiveBusiness = {
              id: x.id,
              bId: x.b_id,
              businessName: x.name,
              default: x.default,
              customization: x.customization,
              meta: x.meta,
            };
          }
          return {
            id: x.id,
            bId: x.b_id,
            businessName: x.name,
            default: x.default,
            customization: x.customization,
            meta: x.meta,
          };
        });
        yield put({
          type: 'saveCurrentUserDetails',
          payload: {
            firstName: response.result.first_name,
            lastName: response.result.last_name,
            contactLang: response.result.contact_language,
            avatar: response.result.avatar,
            ownedBusiness,
          },
        });

        if (defaultActiveBusiness) {
          yield put({
            type: 'od/setEditingBusiness',
            editingBusiness: defaultActiveBusiness,
            cover: false,
          });
        }

        //TBD. 这里call单独的api bearbId
        //这个数据是共用的 应该放在meta里面 landing/user/od要读这个数据
        if (defaultActiveBusiness) {
          yield put({
            type: 'fetchCurrentEditingBusinessPackages',
          });
        }

        // 改状态
        yield put({
          type: 'setLoadedModule',
          key: 'userDetails',
          value: true,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchCurrentEditingBusinessPackages(_, { call, put }) {
      const response = yield call(getCurrentEditingBusinessPackages);
      const result = convertJsonToCamelCase(response.result);

      if (response.success) {
        yield put({
          type: 'saveCurrentEditingBusinessPackages',
          payload: {
            current: result.current,
            next: result.next,
          },
        });
      } else {
        if (response.message) {
          message.error(response.message);
          message.warning('re-directing...');
        }
        yield put({
          type: 'od/clearEditingBusiness',
        });
        yield put({
          type: 'meta/setLoadedModule',
          reload: 'user-status',
        });
        router.replace('/landing');
      }

      //改状态
      yield put({
        type: 'setLoadedModule',
        key: 'currentEditingBusinessPackages',
        value: true,
      });
    },
    *fetchTranslations(
      { requiredLang, transGroups = false, baseTans = false },
      { call, put, select },
    ) {
      /*
        before fetching the need translations pkg,
        need to do a check first.

        transGroups must be passed
      */
      const { currentLang, currentTransGroups } = yield select((state: ConnectState) => ({
        currentLang: state.meta.translations.lang,
        currentTransGroups: Object.keys(state.meta.translations),
      }));

      //do the filter
      if (transGroups && requiredLang === currentLang) {
        transGroups = transGroups.filter((group: string) => !currentTransGroups.includes(group));
      }

      // if no transGroups or no groups need to find
      if ((!transGroups || transGroups.length === 0) && baseTans) {
        yield put({
          type: 'setLoadedModule',
          trans: true,
          key: baseTans,
          value: true,
        });
        return;
      }

      const response = yield call(getTranslations, { transGroups });

      if (response.success) {
        setLocale(languageToLocal[response.result.lang], false);
        yield put({
          type: 'saveTranslations',
          payload: {
            replace: requiredLang === currentLang ? false : true,
            passedTranslations: response.result,
            notify: response.message,
          },
        });

        if (baseTans) {
          //代表这个请求要改 -基 -翻译类
          yield put({
            type: 'setLoadedModule',
            trans: true,
            key: baseTans,
            value: true,
          });
        }
      }
    },
    *fetchClaimInfo({ claimBusinessId, claimToken }, { call, put }) {
      const response = yield call(getClaimBusinessInfo, { claimBusinessId, claimToken });
      const details = convertJsonToCamelCase(response.result);
      // const result = response.result;

      if (response.success) {
        yield put({
          type: 'saveClaimInfo',
          businessAvailable: true,
          details,
        });
        message.success(response.message);
      } else {
        yield put({
          type: 'saveClaimInfo',
          businessAvailable: false,
        });
      }

      // 改状态
      yield put({
        type: 'setLoadedModule',
        key: 'claimInfo',
        value: true,
      });
    },
  },
  reducers: {
    saveTranslations(state = metaSettings, { payload: { replace, passedTranslations, notify } }) {
      const translations = replace
        ? passedTranslations
        : {
            ...state.translations,
            translations: {
              ...state.translations.translations,
              ...passedTranslations.translations,
            },
          };

      return {
        ...state,
        translations,
      };
    },
    initClaimInfo(state = metaSettings, { businessId, token }) {
      const claimInfo = state.claimInfo;
      return {
        ...state,
        claimInfo: {
          ...claimInfo,
          basic: {
            businessId,
            token,
          },
        },
      };
    },
    saveClaimInfo(state = metaSettings, { claimInfoLoaded, businessAvailable, details = false }) {
      const claimInfo = state.claimInfo;
      return {
        ...state,
        claimInfo: {
          ...claimInfo,
          claimInfoLoaded,
          businessAvailable,
          details,
        },
      };
    },
    saveCurrentEditingBusinessPackages(state = metaSettings, { payload }) {
      return {
        ...state,
        editingBusinessPackages: {
          ...payload,
        },
      };
    },
    saveCurrentUserDetails(state = metaSettings, { payload }) {
      return {
        ...state,
        currentUserDetails: {
          ...payload,
        },
      };
    },
    setViewingLang(state = metaSettings, { viewingLang }) {
      if (localStorage) {
        localStorage.setItem('popsup-view-lang', viewingLang);
      }
      return {
        ...state,
        viewingLang,
      };
    },
    setLoadedModule(state = metaSettings, { reload = false, trans = false, key, value }) {
      let currentLoadedModule = state.metaModuleLoaded;
      if (reload) {
        currentLoadedModule = reloadModuleStatus(reload, currentLoadedModule);
      } else {
        if (trans) {
          //改翻译的状态
          currentLoadedModule.api.transPkg[key] = value;
        } else {
          currentLoadedModule.api[key] = value;
          //如果userDetails为false 则editingBusinessPackage也为false
          if (key === 'userDetails' && !value) {
            currentLoadedModule.api.currentEditingBusinessPackages = value;
          }
        }
      }
      const metaModuleLoaded = setLoadedModuleStatus(currentLoadedModule);
      return {
        ...state,
        metaModuleLoaded,
      };
    },
    setClaimSteps(state = metaSettings, { key, value }) {
      const claimInfo = state.claimInfo;
      let steps = { register: -1, login: -1 };
      steps[key] = value;
      return {
        ...state,
        claimInfo: {
          ...claimInfo,
          steps,
        },
      };
    },
    setLoginRedirect(state = metaSettings, { loginRedirect }) {
      return {
        ...state,
        loginRedirect,
      };
    },
    setMobileType(state = metaSettings, { width }) {
      // const mobile = isMobile ? isMobile: state.isMobile;
      // const xs = isXs ? isXs: state.isMobile;
      let isMobile = false;
      let isXs = false;
      if (width <= 767) {
        isMobile = true;
        if (width <= 576) {
          isXs = true;
        }
      }
      return {
        ...state,
        isMobile,
        isXs,
      };
    },
  },

  subscriptions: {
    metaInit({
      dispatch,
      history: {
        location: { pathname, search },
      },
    }): any {
      // console.log(
      //   `[MODEL] pathname: ${pathname}` + ' META model - init - set up meta from url params',
      // );
      // console.log(`search: ${search}`);

      if (POPSUP_WASHOKU_ONLY_SEVER_ENV_KEY !== 'prod') {
        //log -- 这里打印用到的环境变量
        setTimeout(() => {
          console.log(
            `[CONFIG] POPSUP_WASHOKU_ONLY_SEVER_ENV_KEY: ${POPSUP_WASHOKU_ONLY_SEVER_ENV_KEY}`,
          );
          console.log(`[CONFIG] Stripe Key (STRIPE_KEY): ${STRIPE_KEY}`);
        }, 0);
      }

      const { lang, claim_id, claim_token, login_redirect, ...queryParams } = queryString.parse(
        search,
      ) as {
        lang: string;
        claim_id: string;
        claim_token: string;
        login_redirect: string;
        queryParams: any;
      };

      //============处理viewingLang (local | query)==============//
      let viewingLang = lang && LANGUAGELABLE.includes(lang) ? lang : false;

      if (!viewingLang) {
        // if no lang passed from url, try to read from the local storage
        const localLang =
          localStorage && localStorage.getItem('popsup-view-lang')
            ? localStorage.getItem('popsup-view-lang')
            : false;
        viewingLang = localLang && LANGUAGELABLE.includes(localLang) ? localLang : 'en_AU';
      }

      if (lang && !LANGUAGELABLE.includes(lang)) {
        // lang is sometimes, but should be valid
        message.error('Selected viewing language is wrong, set to English as default.');
      }

      dispatch({
        //set显示的语言 - Meta
        type: 'setViewingLang',
        viewingLang,
      });
      //============处理viewingLang - End==============//

      //============处理claim信息 (query)==============//
      let claimId = parseInt(claim_id, 10) ? parseInt(claim_id, 10) : false;

      if (claimId && claim_token) {
        //set claim的ID和token(如果有) - Meta
        dispatch({
          type: 'initClaimInfo',
          businessId: claimId,
          token: claim_token,
        });
      }
      //============处理claim信息 - End ==============//

      //============处理login跳转 (query)==============//
      if (login_redirect && ValidLoginRedirectParams.includes(login_redirect)) {
        // set the login_redirect
        dispatch({
          type: 'setLoginRedirect',
          loginRedirect: login_redirect,
        });
      }
      //============处理login跳转 - End==============//

      router.replace({
        pathname: pathname === '/' ? '/od' : pathname,
        search: stringify({ ...queryParams } as any),
      });
    },
  },
};
export default MetaModel;
