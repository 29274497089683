export default {
  'root.dashboard': 'Pannello di controllo',
  'root.management': 'Managment root',
  'root.products': 'Piatto',
  'root.product-types': 'Dish Types',
  'root.category': 'Categoria',
  'root.campaign': 'Campagna',
  'root.events': 'eventi',
  'root.deals': 'offerte',
  'root.orders': 'Gestione degli ordini',
  'root.bill-process': 'Bill processo',
  'root.order-processing': "Elaborazione dell'ordine",
  'root.invoice-center': 'fattura Centro',
  'root.settings': 'impostazioni',
  'root.personal': 'Impostazione del profilo personale',
  'root.business': 'Profilo aziendale Impostazione',
  'root.print': 'Impostazioni della stampante',
  'root.package': 'Pacchetto',
  'root.premium-services': 'Servizi Premium',
  'root.modifiers': 'Modificatrice',
  'root.modifier-groups': 'Gruppi di modificatori',
  'root.coupon': 'Buono',
  'root.coupons': 'Buoni',
  'root.summary': 'riepilogo',
  'root.product-browses': 'Sfoglia il piatto',
  'root.access-summary': 'Accedi al Riepilogo',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': 'Biglietti elettronici',
  'root.reservation': 'Prenotazione',
  'root.services': 'Servizi avanzati',

  'root.stories': 'Storie',
  'root.support': 'Supporto',
  'root.features': 'Nuove funzionalità',
};
