import { reloadAuthorized } from './Authorized';

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthorizedUserInfo(): string | string[] {
  let authorityString;
  let authority;

  const localStoredUserAuth =
    localStorage &&
    localStorage.getItem('popsup-authorized-user-info') &&
    JSON.parse(localStorage.getItem('popsup-authorized-user-info') as string).user_auth
      ? JSON.parse(localStorage.getItem('popsup-authorized-user-info') as string).user_auth
      : false;

  const sessionStoredUserAuth =
    sessionStorage &&
    sessionStorage.getItem('popsup-authorized-user-info') &&
    JSON.parse(sessionStorage.getItem('popsup-authorized-user-info') as string).user_auth
      ? JSON.parse(sessionStorage.getItem('popsup-authorized-user-info') as string).user_auth
      : false;

  //session 和local都查一遍 如果都没有再返回空
  if (localStoredUserAuth) {
    authorityString = localStoredUserAuth
  }else if(sessionStoredUserAuth){
    authorityString = sessionStoredUserAuth
  }else{
    authorityString = ''
  }

  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }

  return authority;
}

export function setAuthorizedUserInfo(payload: any): void {

  const { remember, authorizedUserInfo } = payload;

  if(localStorage && sessionStorage){
    if(remember){
      localStorage.setItem('popsup-authorized-user-info', JSON.stringify(authorizedUserInfo));
    }else{
      sessionStorage.setItem('popsup-authorized-user-info', JSON.stringify(authorizedUserInfo));
    }
  }

  // auto reload
  reloadAuthorized();
}
