import request from '@/utils/request';
import queryString from 'query-string';

export async function getTranslations(params: any): Promise<any> {
  const { transGroups } = params;
  return request('/api/translations', {
    method: 'GET',
    headers: { bearLang: 'edit' },
    params: { groups: transGroups },
    paramsSerializer: params => {
      const serealizedString = params['groups']
        ? queryString.stringify(params, { arrayFormat: 'index' })
        : queryString.stringify(params);
      return serealizedString;
    },
  });
}

export async function getOssToken(): Promise<any> {
  return request('/api/sts_issue', {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth' },
  });
}

export async function getOssFilePaths(): Promise<any> {
  return request('/api/oss_config', {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth' },
  });
}

export async function getTagNames(): Promise<any> {
  return request('/api/tag', {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
  });
}

export async function getOfferingTypes(): Promise<any> {
  return request('/api/tag/offering_type', {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
  });
}
export async function getAllDishes(): Promise<any> {
  return request('/api/dish/fetch_all', {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
  });
}
export async function getAllModifierGroups(): Promise<any> {
  return request('/api/modifier_group/fetch_all', {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
  });
}

export async function getAllMenus(): Promise<any> {
  return request('/api/menu/fetch_all', {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
  });
}

export async function getBusinessDetail(params: any): Promise<any> {
  const { businessId } = params;
  const expandList = ['printers'];

  return request(`/api/business/${businessId}`, {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
    params: { expand: expandList },
    paramsSerializer: params => {
      const serealizedString = params['expand']
        ? queryString.stringify(params, { arrayFormat: 'index' })
        : queryString.stringify(params);
      return serealizedString;
    },
  });
}

export async function postStsDelete(params: any): Promise<any> {
  const {
    ossSettings: {
      ossTokens: { accessKeyId, accessKeySecret, stsToken },
    },
    objectToDelete,
  } = params;

  const data = {
    access_key_id: accessKeyId,
    access_key_secret: accessKeySecret,
    token: stsToken,
    object: objectToDelete,
  };

  return request(`/api/sts_delete`, {
    method: 'POST',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
    data,
  });
}
