export default {
  'menu.Dashboard': '儀盤表',
  'menu.Management': '管理',
  'menu.Management.Dishes': '菜餚',
  'menu.Management.Category': '菜單',
  'menu.Campaign': '活動',
  'menu.Campaign.Events': '事件',
  'menu.Campaign.Deals': '優惠',
  'menu.Orders': '訂單',
  'menu.Orders.Bill-Process': '帳單支付',
  'menu.Orders.Order-Processing': '訂單處理',
  'menu.Orders.Invoice-Center': '收據中心',
  'menu.Settings': '設置',
  'menu.Settings.Personal-Profile-Setting': '個人檔案設置',
  'menu.Settings.Business-Profile-Setting': '生意檔案設置',
  'menu.Package': '套餐',
  'menu.Premium-Services': '高級服務',
  'menu.Management.Dish-Detail': '菜餚詳情',
  'menu.Management.Dish-Types': '菜式',
  'menu.Management.Dish-Types-Detail': '菜式細節',
  'menu.Management.Category-Detail': '菜單詳情',
  'menu.Management.Modifiers': '修飾符',
  'menu.Management.Modifier-Detail': '修飾符詳細信息',
  'menu.Management.Modifier-Groups': '修飾符組',
  'menu.Management.Modifier-Groups-Detail': '修飾符組詳細信息',
  'menu.Campaign.Event-detail': '事件詳情',
  'menu.Campaign.Deal-detail': '優惠詳情',
  'menu.Home': '主頁',
  'menu.Settings.Print': '打印機設置',
  'menu.Marketing': '營銷',
  'menu.Marketing.Coupons': '優惠券',
  'menu.Marketing.Marketing-Coupon': '優惠券',
  'menu.Analytics': '分析',
  'menu.Analytics.Analytics-Sales': '銷售分析',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': '銷售總結',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': '菜餚銷售分析',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': '菜品瀏覽總結',
  'menu.Analytics.Analytics-Visitors': '訪客分析',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': '訪問摘要',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': '電子票務',
  'menu.reservation': '預訂',
  'menu.services': '高級服務',

  'menu.stories': '公告',
  'menu.support': '客服',
  'menu.features': '新功能',
};
