export default {
  'root.dashboard': 'Приборная доска',
  'root.management': 'Меню Managment',
  'root.products': 'блюдо',
  'root.product-types': 'Dish Types',
  'root.category': 'категория',
  'root.campaign': 'кампания',
  'root.events': 'События',
  'root.deals': 'предложения',
  'root.orders': 'Управление заказами',
  'root.bill-process': 'Билл Процесс',
  'root.order-processing': 'Обработка заказов',
  'root.invoice-center': 'Центр счета-фактуры',
  'root.settings': 'настройки',
  'root.personal': 'Личные настройки профиля',
  'root.business': 'Бизнес-профиль Настройка',
  'root.print': 'Настройки принтера',
  'root.package': 'пакет',
  'root.premium-services': 'Премиум-сервис',
  'root.modifiers': 'модификатор',
  'root.modifier-groups': 'Группы модификаторов',
  'root.coupon': 'купон',
  'root.coupons': 'купоны',
  'root.summary': 'резюме',
  'root.product-browses': 'Обзоры блюд',
  'root.access-summary': 'Сводка доступа',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': 'Электронные билеты',
  'root.reservation': 'Бронирование',
  'root.services': 'Продвинутые услуги',

  'root.stories': 'Истории',
  'root.support': 'Поддержка',
  'root.features': 'Новые функции',
};
