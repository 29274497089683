import { getReloadModuleInOd } from './../utils/odModules';
import { Reducer } from 'redux';
import odSettings, { OdSettings } from '../../config/odSettings';
import { Subscription, Effect } from 'dva';
import { LANGUAGELABLE } from '@/constants/Languages';
import { message } from 'antd';
import { ConnectState } from '@/models/connect';
import {
  getTranslations,
  getOssToken,
  getOssFilePaths,
  getTagNames,
  getOfferingTypes,
  getAllDishes,
  getAllMenus,
  getBusinessDetail,
  postStsDelete,
  getAllModifierGroups,
} from '@/services/od';
import { getLoadedModuleStatus as metaGet } from '@/utils/metaModules';
import { setLoadedModuleStatus as odSet, reloadModuleStatus } from '@/utils/odModules';
import _ from 'lodash';
import { mapKeys, camelCase } from 'lodash';
import { convertJsonToCamelCase } from '@/utils/utils';
import { odTranslationGroups, metaTranslationOfOdGroups } from '@/constants/translationGroups';
import router from 'umi/router';
import { stringify } from 'querystring';

export interface OdModelType {
  namespace: string;
  state: OdSettings;
  effects: {
    fetchMeta: Effect;
    fetchTranslations: Effect;
    fetchOssStsToken: Effect;
    deleteFromOss: Effect;

    fetchEditingBuisinessDetails: Effect;
    fetchAllDishes: Effect;
    fetchAllMenus: Effect;
    fetchAllModifierGroups: Effect;
    fetchTagList: Effect;
    fetchOfferingTypeList: Effect;
  };
  reducers: {
    //基础配置
    setOdLayoutCollapsed: Reducer<OdSettings>;
    setEditingLang: Reducer<OdSettings>;
    saveOdTranslations: Reducer<OdSettings>;

    //oss相关
    saveOssToken: Reducer<OdSettings>;
    saveOssFilePaths: Reducer<OdSettings>;

    //session里面的bid
    saveEditingBusinessDetails: Reducer<OdSettings>;
    initEditingBusiness: Reducer<OdSettings>;
    setEditingBusiness: Reducer<OdSettings>;
    clearEditingBusiness: Reducer<OdSettings>;

    //预览页面&&数据
    setShowPreview: Reducer<OdSettings>;
    setPreviewPageData: Reducer<OdSettings>;

    //状态机相关
    setReloadAction: Reducer<OdSettings>;
    setLoadedModule: Reducer<OdSettings>;

    //服务器资源
    saveAllDishes: Reducer<OdSettings>;
    saveAllModifierGroups: Reducer<OdSettings>;
    saveAllMenus: Reducer<OdSettings>;
    saveTagList: Reducer<OdSettings>;
    saveOfferingTypeList: Reducer<OdSettings>;
  };
  subscriptions: {
    odInit: Subscription;
  };
}

const OdModel: OdModelType = {
  namespace: 'od',
  state: odSettings,
  effects: {
    *fetchMeta({ reload = false }, { put, all, select }) {
      //如果reload为true则先清空所有的trans
      if (reload) {
        yield put({
          type: 'meta/setLoadedModule',
          reload,
        });
      }
      //init the od layout data
      /*
        1. transPkg
        2. userDetails

        TBD.
       */
      const { requiredLang, currentUser, metaModuleLoaded } = yield select(
        (state: ConnectState) => ({
          requiredLang: state.meta.viewingLang,
          currentUser: state.user.currentUser,
          metaModuleLoaded: state.meta.metaModuleLoaded,
        }),
      );

      const userId = currentUser && currentUser.id ? currentUser.id : false;

      const transGroups = metaTranslationOfOdGroups;

      //这里加载od layout的data --> od meta
      //transPkg && userDetails
      let payload = { requiredLang, userId, transGroups };

      let getActions = metaGet('od', metaModuleLoaded, payload);

      if (getActions) {
        let actionArray: any = [];

        _.forEach(getActions, (value: any) => actionArray.push(put(value)));

        yield all(actionArray);
      }
    },

    *fetchTranslations(_, { call, put, select }) {
      const transGroups = odTranslationGroups; // TBD. minimal required transPkg for od only

      const response = yield call(getTranslations, { transGroups });
      if (response.success) {
        yield put({
          type: 'saveOdTranslations',
          payload: response.result,
        });

        // message.success(response.message);
        yield put({
          type: 'setLoadedModule',
          key: 'trans',
          value: true,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchTagList(_, { call, put }) {
      const response = yield call(getTagNames);
      if (response.success) {
        const data = convertJsonToCamelCase(response.result);
        const tagList = {
          business: data
            .filter((x: any) => x.type !== 'ingredient' && x.type !== 'offering_type')
            .map((x: any) => ({ id: x.id, tagName: x.tagName })),
          ingredient: data
            .filter((x: any) => x.type === 'ingredient')
            .map((x: any) => ({ id: x.id, tagName: x.tagName })),
        };
        yield put({
          type: 'saveTagList',
          tagList,
        });

        // message.success(response.message);
        yield put({
          type: 'setLoadedModule',
          key: 'tag',
          subKey: 'tagList',
          value: true,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchAllDishes(_, { call, put }) {
      const response = yield call(getAllDishes);
      if (response.success) {
        const data = convertJsonToCamelCase(response.result);
        const allDishes = data.map((item: any, key: any) => ({ ...item, key }));

        yield put({
          type: 'saveAllDishes',
          allDishes,
        });

        yield put({
          type: 'setLoadedModule',
          key: 'allDishes',
          value: true,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchAllMenus(_, { call, put }) {
      const response = yield call(getAllMenus);
      if (response.success) {
        const result = convertJsonToCamelCase(response.result);
        //改成camelcase并且加上key属性，用来给transfer api识别作为行健
        const allMenus = result.map((x: any, idx: number) => ({
          id: x.id,
          name: x.name,
          key: idx,
        }));

        yield put({
          type: 'saveAllMenus',
          allMenus,
        });

        yield put({
          type: 'setLoadedModule',
          key: 'allMenus',
          value: true,
        });
      } else {
        message.error(response.message);
      }
    },
    *fetchAllModifierGroups(_, { call, put }) {
      const response = yield call(getAllModifierGroups);
      if (response.success) {
        const result = convertJsonToCamelCase(response.result);
        //改成camelcase并且加上key属性
        const allModifierGroups = result.map((x: any, idx: number) => ({
          id: x.id,
          name: x.name,
          key: idx,
        }));

        yield put({
          type: 'saveAllModifierGroups',
          allModifierGroups,
        });

        yield put({
          type: 'setLoadedModule',
          key: 'allModifierGroups',
          value: true,
        });
      } else {
        message.error(response.message);
      }
    },
    *fetchOfferingTypeList(_, { call, put }) {
      const response = yield call(getOfferingTypes);
      if (response.success) {
        const data = convertJsonToCamelCase(response.result);
        const offeringTypeList = data.map((x: any) => ({ id: x.id, tagName: x.name }));

        yield put({
          type: 'saveOfferingTypeList',
          offeringTypeList,
        });

        // message.success(response.message);
        yield put({
          type: 'setLoadedModule',
          key: 'tag',
          subKey: 'offeringTypeList',
          value: true,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchEditingBuisinessDetails({ businessId }, { call, put }) {
      const response = yield call(getBusinessDetail, { businessId });
      if (response.success) {
        const editingBusinessDetails = convertJsonToCamelCase(response.result);
        yield put({
          type: 'saveEditingBusinessDetails',
          editingBusinessDetails,
        });

        // 改状态
        yield put({
          type: 'setLoadedModule',
          key: 'buisinessDetails',
          value: true,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *deleteFromOss({ payload }, { call, put, select }) {
      const { ossSettings } = yield select((state: ConnectState) => ({
        ossSettings: state.od.ossSettings,
      }));

      const { objectToDelete, spaceName, callBackObj = false } = payload;

      const response = yield call(postStsDelete, {
        ossSettings,
        objectToDelete: objectToDelete,
      });

      const validSpaceName = [
        'odSettingBusiness',
        'odDish',
        'odMenu',
        'odEvent',
        'odDeal',
        'odModifier',
      ];

      if (response.success) {
        //先执行一些特殊操作：
        //1. personal删除avatar
        if (spaceName === 'odSettingPersonal' && callBackObj) {
          yield put(callBackObj); //person删除自己的avatar 这个要关闭自身的processing
          yield put({
            type: 'fetchMeta',
            reload: 'user-status',
          });
        } else if (spaceName && validSpaceName.includes(spaceName)) {
          //最后处理一般情况
          yield put(getReloadModuleInOd(false, spaceName, 'stay', 'update'));
        }
        if (response.message) {
          message.success(response.message);
        }
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchOssStsToken(_, { call, put, select }) {
      const currentTime = new Date().getTime(); //当前时间

      const { ossSettings, ossFilePaths = false } = yield select((state: ConnectState) => ({
        ossSettings: state.od.ossSettings,
        ossFilePaths: state.od.ossFilePaths,
      }));

      let receivedPathTable;
      if (!ossFilePaths) {
        //没有这个表就先去取
        const response = yield call(getOssFilePaths);
        if (response.success) {
          receivedPathTable = convertJsonToCamelCase(response.result);
          yield put({
            type: 'saveOssFilePaths',
            payload: receivedPathTable,
          });
        } else {
          message.error(response.message);
          return false;
        }
      }

      const ossPathTable = ossFilePaths ? ossFilePaths : receivedPathTable;

      if (
        ossSettings &&
        ossSettings.expiration &&
        ossSettings.expiration - currentTime > 5 * 60 * 1000
      ) {
        //存在token并且有效时间大于5min
        return { ossSettings, ossPathTable };
      }

      const response = yield call(getOssToken);
      if (response.success) {
        const payload = {
          ossTokens: {
            region: response.result.region,
            bucket: response.result.bucket,
            accessKeyId: response.result.accessKeyId,
            accessKeySecret: response.result.accessKeySecret,
            stsToken: response.result.token,
          },
          callBack: {
            callbackUrl: response.result.callbackUrl,
            callbackBody: response.result.callbackBody,
            callbackBodyType: response.result.callbackBodyType,
          },
          customValue: response.result.customValue,
          expiration: Number(response.result.expiration),
        };

        yield put({
          type: 'saveOssToken',
          payload,
        });

        return { ossSettings: payload, ossPathTable };
      } else {
        if (response.message) {
          message.error(response.message);
        }
        return false;
      }
    },
  },
  reducers: {
    saveOdTranslations(state = odSettings, { payload }) {
      return {
        ...state,
        translations: payload,
      };
    },
    setOdLayoutCollapsed(state = odSettings, { collapsed }) {
      return {
        ...state,
        collapsed,
      };
    },
    setEditingBusiness(state = odSettings, { editingBusiness, cover = false }) {
      let finalBusiness: any; //TBD.
      if (
        (cover && sessionStorage) ||
        (!cover && !sessionStorage.getItem('popsup-od-editing-business-info'))
      ) {
        //cover 为true 或者虽然不cover但是sessionStorage里面没有
        //则一起更改 redux 和 sessionStorage
        const entity = {
          id: editingBusiness.id,
          b_id: editingBusiness.bId,
          business_name: editingBusiness.businessName,
          default: editingBusiness.default,
          customization: editingBusiness.customization,
          meta: editingBusiness.meta,
        };
        sessionStorage.setItem('popsup-od-editing-business-info', JSON.stringify(entity));
        finalBusiness = editingBusiness;
      }

      if (!cover && sessionStorage.getItem('popsup-od-editing-business-info')) {
        const parsedValue = JSON.parse(
          sessionStorage.getItem('popsup-od-editing-business-info') as string,
        );
        //cover 为false 但是sessionStorage里面有值
        //--需要判断
        //1. 如果此时两个business id一样 则替换掉
        if (editingBusiness.id === parsedValue.id) {
          const entity = {
            id: editingBusiness.id,
            b_id: editingBusiness.bId,
            business_name: editingBusiness.businessName,
            default: editingBusiness.default,
            customization: editingBusiness.customization,
            meta: editingBusiness.meta,
          };
          sessionStorage.setItem('popsup-od-editing-business-info', JSON.stringify(entity));
          finalBusiness = editingBusiness;
        } else {
          //2. 如果两个b_id不一样 则保持原有的session里面的值不动
          //则把sessionStorgae里面的值取出来set到redux里面
          const sessionBusiness =
            parsedValue && parsedValue.b_id && parsedValue.business_name
              ? parsedValue
              : editingBusiness;

          finalBusiness = {
            ...mapKeys(sessionBusiness, (_, key: string) => camelCase(key)),
          };
        }
      }
      return {
        ...state,
        editingBusiness: finalBusiness,
      };
    },
    clearEditingBusiness(state = odSettings) {
      sessionStorage.removeItem('popsup-od-editing-business-info');
      return {
        ...state,
        editingBusiness: false,
      };
    },
    setEditingLang(state = odSettings, { editingLang }) {
      if (localStorage) {
        //init是true的时候就不用setlocalStorage了
        localStorage.setItem('popsup-edit-lang', editingLang);
      }
      return {
        ...state,
        editingLang,
      };
    },

    saveOssToken(state = odSettings, { payload }) {
      return {
        ...state,
        ossSettings: {
          ...payload,
        },
      };
    },
    saveOssFilePaths(state = odSettings, { payload }) {
      //TBD.
      return {
        ...state,
        ossFilePaths: {
          ...payload,
        },
      };
    },
    setLoadedModule(state = odSettings, { reload = false, key, subKey = false, value }) {
      let currentLoadedModule = state.odModuleLoaded;
      if (reload) {
        currentLoadedModule = reloadModuleStatus(reload, currentLoadedModule);
      } else {
        if (key && subKey) {
          currentLoadedModule.api[key][subKey] = value;
        } else {
          currentLoadedModule.api[key] = value;
        }
      }
      const odModuleLoaded = odSet(currentLoadedModule);
      return {
        ...state,
        odModuleLoaded,
      };
    },
    initEditingBusiness(state = odSettings, { editingBusiness }) {
      return {
        ...state,
        editingBusiness,
      };
    },
    setReloadAction(
      state = odSettings,
      {
        showActioningMessage = false,
        spaceName,
        reloadType = false,
        redirectUrl = false,
        pageModelKey = false,
      },
    ) {
      let reloadAction: any = { showActioningMessage, spaceName };

      if (redirectUrl) {
        reloadAction = { ...reloadAction, redirectUrl };
      } else if (reloadType) {
        reloadAction = { ...reloadAction, reloadType };
      } else if (pageModelKey) {
        reloadAction = { ...reloadAction, pageModelKey };
      }

      return {
        ...state,
        reloadAction,
      };
    },
    setShowPreview(state = odSettings, { showPreview }) {
      return {
        ...state,
        showPreview,
      };
    },
    setPreviewPageData(state = odSettings, { previewPageData }) {
      return {
        ...state,
        previewPageData,
      };
    },
    saveAllDishes(state = odSettings, { allDishes }) {
      return {
        ...state,
        allDishes,
      };
    },
    saveAllMenus(state = odSettings, { allMenus }) {
      return {
        ...state,
        allMenus,
      };
    },
    saveAllModifierGroups(state = odSettings, { allModifierGroups }) {
      return {
        ...state,
        allModifierGroups,
      };
    },
    saveTagList(state = odSettings, { tagList }) {
      return {
        ...state,
        tagList,
      };
    },
    saveOfferingTypeList(state = odSettings, { offeringTypeList }) {
      return {
        ...state,
        offeringTypeList,
      };
    },
    saveEditingBusinessDetails(state = odSettings, { editingBusinessDetails }) {
      return {
        ...state,
        editingBusinessDetails,
      };
    },
  },
  subscriptions: {
    odInit({
      dispatch,
      history: {
        location: { pathname, search },
      },
    }): any {
      // console.log(
      //   `[MODEL] pathname: ${pathname}` + ' OD model - init - set up od from localStorage',
      // );
      // console.log(`search: ${search}`);

      //============处理editingLang (local)==============//
      const localEditingLang = //取出loacl里面的editing language
        localStorage && localStorage.getItem('popsup-edit-lang')
          ? localStorage.getItem('popsup-edit-lang')
          : false;

      const editingLang =
        localEditingLang && LANGUAGELABLE.includes(localEditingLang) ? localEditingLang : 'en_AU';

      if (localEditingLang && !LANGUAGELABLE.includes(localEditingLang)) {
        // lang is sometimes, but should be valid
        message.error('Selected editing language is wrong, set to English as default.');
      }

      dispatch({
        //set编辑的语言 - Od
        type: 'setEditingLang',
        editingLang,
      });
      //============处理editingLang - End ==============//

      //============处理editingBusiness (session)==============//
      const sessionEditingBusiness = //取出session里面的od-editing-business-info 查看里面的b_id和business_name
        sessionStorage &&
        sessionStorage.getItem('popsup-od-editing-business-info') &&
        JSON.parse(sessionStorage.getItem('popsup-od-editing-business-info') as string)
          ? JSON.parse(sessionStorage.getItem('popsup-od-editing-business-info') as string)
          : false;

      const editingBusiness =
        sessionEditingBusiness.b_id && sessionEditingBusiness.business_name
          ? sessionEditingBusiness
          : false; //TBD.

      if (editingBusiness) {
        dispatch({
          //set编辑的语言 - Od
          type: 'initEditingBusiness',
          editingBusiness: {
            id: Number(editingBusiness.id),
            bId: Number(editingBusiness.b_id),
            businessName: editingBusiness.business_name,
            default: editingBusiness.default,
          },
        });
      }
      //============处理editingBusiness (session) - End==============//
    },
  },
};
export default OdModel;
