export default {
  'menu.Dashboard': 'Приборная доска',
  'menu.Management': 'Меню Managment',
  'menu.Management.Dishes': 'блюдо',
  'menu.Management.Category': 'категория',
  'menu.Campaign': 'кампания',
  'menu.Campaign.Events': 'События',
  'menu.Campaign.Deals': 'предложения',
  'menu.Orders': 'Управление заказами',
  'menu.Orders.Bill-Process': 'Билл Процесс',
  'menu.Orders.Order-Processing': 'Обработка заказов',
  'menu.Orders.Invoice-Center': 'Центр счета-фактуры',
  'menu.Settings': 'настройки',
  'menu.Settings.Personal-Profile-Setting': 'Личные настройки профиля',
  'menu.Settings.Business-Profile-Setting': 'Бизнес-профиль Настройка',
  'menu.Package': 'пакет',
  'menu.Premium-Services': 'Премиум-сервис',
  'menu.Management.Dish-Detail': 'Блюдо Detail',
  'menu.Management.Dish-Types': 'Типы блюд',
  'menu.Management.Dish-Types-Detail': 'Подробная информация о типах блюд',
  'menu.Management.Category-Detail': 'Меню Detail',
  'menu.Management.Modifiers': 'Модификаторы',
  'menu.Management.Modifier-Detail': 'Деталь модификатора',
  'menu.Management.Modifier-Groups': 'Группы модификаторов',
  'menu.Management.Modifier-Groups-Detail': 'Детали групп модификаторов',
  'menu.Campaign.Event-detail': 'Сведения о событии',
  'menu.Campaign.Deal-detail': 'Deal Detail',
  'menu.Home': 'Главная',
  'menu.Settings.Print': 'Настройки принтера',
  'menu.Marketing': 'маркетинг',
  'menu.Marketing.Coupons': 'купоны',
  'menu.Marketing.Marketing-Coupon': 'купон',
  'menu.Analytics': 'Аналитика',
  'menu.Analytics.Analytics-Sales': 'Аналитика продаж',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': 'Сводка продаж',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': 'Продажа блюд',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': 'Сводка просмотров блюд',
  'menu.Analytics.Analytics-Visitors': 'Анализ посетителей',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': 'Сводка доступа',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': 'Электронные билеты',
  'menu.reservation': 'Бронирование',
  'menu.services': 'Продвинутые услуги',

  'menu.stories': 'Истории',
  'menu.support': 'Поддержка',
  'menu.features': 'Новые функции',
};
