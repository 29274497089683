export interface MetaSettings {
  translations: {
    [key: string]: any;
  };
  metaModuleLoaded: {
    api: {
      caAdvertise: boolean; //ca
      currentCartStatus: boolean; //ca
      currentEditingBusinessPackages: boolean;
      businessConfigs: boolean;
      claimInfo: boolean;
      userDetails: boolean;
      transPkg: {
        ca: boolean;
        od: boolean;
        landing: boolean;
        user: boolean;
        translation: boolean;
      };
    };

    ca: boolean;
    od: boolean;
    landing: boolean;
    user: boolean;
    translation: boolean;
  };
  viewingLang: string;

  claimInfo: {
    businessAvailable?: boolean;
    basic?: {
      businessId: number;
      token: string;
    };
    steps: {
      register: number;
      login: number;
    };
    details?: {
      businessName?: string;
      businessAddress?: string;
      businessEmail?: string;
    };
  };
  currentUserDetails: {
    firstName: string;
    lastName: string;
    contactLang: string;
    ownedBusiness: any[];
  };
  editingBusinesPackages: any;
  loginRedirect: string | false;

  isMobile: boolean;
  isXs: boolean;
  version: string;
}

export default {
  translations: {},
  metaModuleLoaded: {
    api: {
      caAdvertise: false,
      currentCartStatus: false, //ca
      currentEditingBusinessPackages: false,
      businessConfigs: false,
      claimInfo: false,
      userDetails: false,
      transPkg: {
        ca: false,
        od: false,
        landing: false,
        user: false,
        translation: false,
      },
    },
    ca: false,
    od: false,
    landing: false,
    user: false,
    translation: false,
  },

  viewingLang: 'en_AU',
  claimInfo: {
    steps: {
      register: -1,
      login: -1,
    },
  },
  loginRedirect: false,
  currentUserDetails: {},
  isMobile: false,
  isXs: false,
  version: '5.1.0',
} as MetaSettings;
