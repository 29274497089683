import { message } from 'antd';
import { Effect, Subscription } from 'dva';
import { Reducer } from 'redux';
import userSettings, { UserSettings } from '../../config/userSettings';
import { getPageQuery } from '@/utils/utils';
import { readUserInfoFromStorage, removeUserInfoFromStorage } from '@/utils/user';
import { stringify, parse } from 'querystring';
import { refreshUserByToken } from '@/services/user';
import { setAuthorizedUserInfo } from '@/utils/authority';
import { router } from 'umi';
import { ConnectState } from '@/models/connect';
import { getLoadedModuleStatus } from '@/utils/metaModules';
import _ from 'lodash';
import { metaTranslationOfUserGroups } from '@/constants/translationGroups';
import queryString from 'query-string';

export interface UserModelType {
  namespace: 'user';
  state: UserSettings;
  effects: {
    fetchMeta: Effect;
    refreshCurrentUserToken: Effect;
  };
  reducers: {
    saveCurrentUserToken: Reducer<UserSettings>;
    clearCurrentUserToken: Reducer<UserSettings>;
    setUserRefreshInfo: Reducer<UserSettings>;
    initCurrentUserToken: Reducer<UserSettings>;
    setSelectedPackageInfo: Reducer<UserSettings>;
    //user里的是给购买package用
    setPackageCheckoutResult: Reducer<UserSettings>;
    saveStripeInfo: Reducer<UserSettings>;
  };
  subscriptions: {
    userInit: Subscription;
  };
}

const UserModel: UserModelType = {
  namespace: 'user',
  state: userSettings,
  effects: {
    *fetchMeta({ reload = false }, { put, select, all }) {
      //如果reload为true则先清空所有的trans
      if (reload) {
        yield put({
          type: 'meta/setLoadedModule',
          reload,
        });
      }
      /*
        1. transPkg
        2. claimInfo
       */
      const { requiredLang, claimBasic, currentUser, metaModuleLoaded } = yield select(
        (state: ConnectState) => ({
          requiredLang: state.meta.viewingLang,
          claimBasic: state.meta.claimInfo.basic,
          currentUser: state.user.currentUser,
          metaModuleLoaded: state.meta.metaModuleLoaded,
        }),
      );

      // do a check first and set a default value
      const userId = currentUser && currentUser.id ? currentUser.id : false;

      const transGroups = metaTranslationOfUserGroups;

      const payload = { claimBasic, userId, requiredLang, transGroups };

      let getActions = getLoadedModuleStatus('user', metaModuleLoaded, payload);

      if (getActions) {
        let actionArray: any = [];

        _.forEach(getActions, (value: any) => actionArray.push(put(value)));
        yield all(actionArray);
      }
    },
    *refreshCurrentUserToken({ refreshPayload }, { call, put }) {
      const response = yield call(refreshUserByToken, { ...refreshPayload });
      if (response.success) {
        //200
        yield put({
          type: 'saveCurrentUserToken',
          payload: response.result,
          remember: true,
        });
        message.success(response.message);
        //成功则清空userRefreshInfo
        yield put({
          type: 'setUserRefreshInfo',
          refreshInfo: false,
        });
      } else {
        yield put({
          type: 'user/clearCurrentUserToken',
        });
        yield put({
          type: 'od/clearEditingBusiness',
        });

        router.replace('/user/login');

        if (response.message) {
          message.error(response.message);
        }
      }
    },
  },
  reducers: {
    saveCurrentUserToken(state = userSettings, { payload, remember = false }) {
      const { user_id, user_email, user_status, user_auth, token } = payload;
      const token_receive_at = new Date().getTime(); //存的时候记录当前时间
      setAuthorizedUserInfo({ authorizedUserInfo: { ...payload, token_receive_at }, remember });

      return {
        ...state,
        currentUser: {
          id: user_id,
          email: user_email,
          auth: user_auth,
          status: user_status,
          token: token,
          tokenReceivedAt: token_receive_at,
        },
      };
    },
    clearCurrentUserToken(state = userSettings) {
      removeUserInfoFromStorage();
      return {
        ...state,
        currentUser: false,
      };
    },
    setUserRefreshInfo(state = userSettings, { refreshInfo }) {
      return {
        ...state,
        refreshInfo,
      };
    },
    initCurrentUserToken(state = userSettings, { payload }) {
      const { user_id, user_email, user_status, user_auth, token, token_receive_at } = payload;
      return {
        ...state,
        currentUser: {
          id: user_id,
          email: user_email,
          auth: user_auth,
          status: user_status,
          token: token,
          tokenReceivedAt: token_receive_at,
        },
      };
    },
    setSelectedPackageInfo(
      state = userSettings,
      { nameKey, price, period, clear = false, init = false },
    ) {
      // when clear is true, reset into empty {}
      let selectedPackageInfo = clear ? {} : state.selectedPackageInfo;

      // setting default period as Yearly
      if (init) selectedPackageInfo.period = 'yearly';

      if (nameKey) selectedPackageInfo.nameKey = nameKey;
      if (price) selectedPackageInfo.price = price;
      if (period) selectedPackageInfo.period = period;

      return {
        ...state,
        selectedPackageInfo,
      };
    },
    setPackageCheckoutResult(state = userSettings, { packageCheckoutResult }) {
      return {
        ...state,
        packageCheckoutResult,
      };
    },
    saveStripeInfo(state = userSettings, { sessionId }) {
      const stripeInfo = state.stripeInfo;

      return {
        ...state,
        stripeInfo: { ...stripeInfo, sessionId },
      };
    },
  },

  subscriptions: {
    userInit({
      dispatch,
      history: {
        location: { pathname, search },
      },
    }): any {
      // console.log(`[MODEL] pathname: ${pathname}` + ' USER model - init - set up user from url params');
      // console.log(`search: ${search}`);

      const {
        register_id,
        register_token,
        package_checkout_result,

        ...queryParams
      } = queryString.parse(search) as {
        register_id: string;
        register_token: string;
        package_checkout_result: string;
        queryParams: any;
      };

      //=============== 处理当前或初始化用户信息 (local ｜query) ===============//

      //这里init refreshInfo 和 currentUser
      //为了防止数据混淆 这两个值只能init一个
      //有refreshInfo 则不管其他的直接set， 结束 这一项只能从url中取
      //没有refreshInfo 检查是不是有currentUser, 如果有则set, 结束 这一项从localStorage里取
      let registerId = parseInt(register_id, 10) ? parseInt(register_id, 10) : false;
      let registerToken = register_token ? register_token : false;

      if (registerId && registerToken) {
        dispatch({
          type: 'setUserRefreshInfo',
          refreshInfo: {
            userId: registerId,
            refreshToken: registerToken,
          },
        });
      } else {
        const userInfo = readUserInfoFromStorage();
        //取到localStorage 或者 sessionStorage的 userInfo
        if (userInfo && userInfo.user_id && userInfo.token) {
          dispatch({
            type: 'initCurrentUserToken',
            payload: userInfo,
          });
        }
      }
      //=============== 处理当前用户信息 (local ｜query) - End ===============//

      //=============== 处理stripe付款后的请求 checkout_result (query) - Start ===============//

      //** 校验checkout result是否为有效参数 *///
      const validPackageCheckoutResults = ['success'];

      if (validPackageCheckoutResults.includes(package_checkout_result)) {
        dispatch({
          type: 'setPackageCheckoutResult',
          packageCheckoutResult: package_checkout_result,
        });
      }
      //=============== 处理stripe付款后的请求 checkout_result (query) - End ===============//

      router.replace({
        pathname: pathname === '/' ? '/od' : pathname,
        search: stringify({ ...queryParams } as any),
      });
    },
  },
};

export default UserModel;
