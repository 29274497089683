export const LANGUAGETYPE = [
  {
    type: 'en_AU',
    text: 'English',
  },
  {
    type: 'zh_CN',
    text: '简体中文',
  },
  {
    type: 'zh_HK',
    text: '繁體中文',
  },
  {
    type: 'ru_RU',
    text: 'Русский',
  },
  {
    type: 'ja_JP',
    text: '日本語',
  },
  {
    type: 'fr_FR',
    text: 'Français',
  },
  {
    type: 'de_DE',
    text: 'Deutsch',
  },
  {
    type: 'it_IT',
    text: 'Italiano',
  },
  {
    type: 'es_ES',
    text: 'Español',
  },
  {
    type: 'ko_KR',
    text: '한국어',
  },
];

export const LANGUAGELABLE = [
  'en_AU',
  'zh_CN',
  'zh_HK',
  'ru_RU',
  'ja_JP',
  'fr_FR',
  'de_DE',
  'it_IT',
  'es_ES',
  'ko_KR',
];

export const languageToLocal = {
  en_AU: 'en-US',
  zh_CN: 'zh-CN',
  zh_HK: 'zh-TW',
  ru_RU: 'ru-RU',
  ja_JP: 'ja-JP',
  fr_FR: 'fr-FR',
  de_DE: 'de-DE',
  it_IT: 'it-IT',
  es_ES: 'es-ES',
  ko_KR: 'ko-KR',
};

export const languageLabels = {
  en_AU: 'English',
  zh_CN: '简体中文',
  zh_HK: '繁體中文',
  ru_RU: 'Русский',
  ja_JP: '日本語',
  fr_FR: 'Français',
  de_DE: 'Deutsch',
  it_IT: 'Italiano',
  es_ES: 'Español',
  ko_KR: '한국어',
};

// export const languageIcons = {
//   'en-AU': '🇦🇺',
//   'zh-CN': '🇨🇳',
//   'zh-HK': '🇭🇰',
//   'ru-RU': '🇷🇺',
//   'ja-JP': '🇯🇵',
//   'fr-FR': '🇫🇷',
//   'de-DE': '🇩🇪',
//   'it-IT': '🇮🇹',
//   'es-ES': '🇪🇸',
//   'ko-KR': '🇰🇷',
// }
