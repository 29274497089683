import React, { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';
import Redirect from 'umi/redirect';
import { connect } from 'dva';
import { getMapStateToProps } from '@/utils/utils';
import PageLoading from '@/components/PageLoading';

const CaValidBusinessGate: React.FC<any> = (props: any) => {
  const { meta, children, ca, dispatch } = props;

  //在取数据之前必须要先验证这个business_id是不是合法
  useEffect(() => {
    if (!ca.isValidBusinessId) {
      dispatch({
        type: 'ca/fetchBusinessIdStatus',
      });
    }
  }, []);

  if (!ca.isValidBusinessId) {
    return <PageLoading />;
  }

  return <>{children}</>;
};

export default connect(getMapStateToProps(['meta', 'ca']))(CaValidBusinessGate);
