import fetch from 'dva/fetch';
import { getPageQuery } from '@/utils/utils';
import { validBusinessIdFormat } from '@/constants/ValidNumberFormat';

export const dva = {
  config: {
    onError(err: any) {
      err.preventDefault();
      console.error(err.message);
    },
  },
};

export const patchRoutes = (routes: any) => {
  const { ca_id = '', ...queryParams } = getPageQuery() as {
    ca_id: string;
    queryParams: any;
  };

  let caId = ca_id && validBusinessIdFormat.test(ca_id) ? String(ca_id) : false;

  if (!caId) {
    // if no city passed from url, try to read from the local storage
    const localCaId =
      localStorage && localStorage.getItem('popsup-ca-viewing-id')
        ? localStorage.getItem('popsup-ca-viewing-id')
        : false;
    caId = localCaId && validBusinessIdFormat.test(localCaId) ? String(localCaId) : false; //TBD.
  }

  if (caId) {
    fetch('/api/ca/routes', { headers: { lang: 'en_AU', bid: caId } })
      .then(res => res.json())
      .then(ret => {
        const replaceRoutes = ret.result;
        console.log('[CONFIG] Current CA root is: ', replaceRoutes);

        const changeHomeToMenus = replaceRoutes && replaceRoutes.home === 'menus' ? true : false;

        if (changeHomeToMenus) {
          routes.forEach((element: any) => {
            if (element.path === '/') {
              element.routes.forEach((section: any) => {
                if (section.path === '/ca') {
                  section.routes.forEach((caSubSection: any) => {
                    if (caSubSection.pageIndex === 'main') {
                      caSubSection.routes.forEach((item: any) => {
                        if (
                          item.pageIndex === 'caHomePath' ||
                          item.pageIndex === 'caHomeRedirect'
                        ) {
                          item.redirect = '/ca/menus';
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
  }
};
