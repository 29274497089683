export default {
  'root.dashboard': 'Instrumententafel',
  'root.management': 'Menü Managment',
  'root.products': 'Gericht',
  'root.product-types': 'Dish Types',
  'root.category': 'Kategorie',
  'root.campaign': 'Kampagne',
  'root.events': 'Veranstaltungen',
  'root.deals': 'Angebote',
  'root.orders': 'Auftragsverwaltung',
  'root.bill-process': 'Bill Prozess',
  'root.order-processing': 'Auftragsbearbeitung',
  'root.invoice-center': 'Rechnungszentrum',
  'root.settings': 'die Einstellungen',
  'root.personal': 'Persönliches Profil Rahmen',
  'root.business': 'Unternehmensprofil Rahmen',
  'root.print': 'Druckereinstellungen',
  'root.package': 'Paket',
  'root.premium-services': 'Premium service',
  'root.modifiers': 'Modifikator',
  'root.modifier-groups': 'Modifikatorgruppen',
  'root.coupon': 'Coupon',
  'root.coupons': 'Gutscheine',
  'root.summary': 'Zusammenfassung',
  'root.product-browses': 'Gericht durchsucht',
  'root.access-summary': 'Zusammenfassung aufrufen',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': 'Elektronische Tickets',
  'root.reservation': 'Reservierung',
  'root.services': 'Erweiterte Dienstleistungen',

  'root.stories': 'Geschichten',
  'root.support': 'Support',
  'root.features': 'Neue Funktionen',
};
