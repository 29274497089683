import pay from '@/pages/user/package/pay';
import request from '@/utils/request';

const cartTypeConvert = {
  individual: 'individual_order',
  group: 'group_order',
};

export async function getCurrentCartStatus(params: any): Promise<any> {
  const {
    currentCart: { cartId, cartType },
  } = params;
  return request(`/api/${cartTypeConvert[cartType]}/status/${cartId}`, {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

export async function exitCurrentRoom(params: any): Promise<any> {
  const { cartId, participantId } = params;
  return request(`/api/group_order/leave/${cartId}`, {
    method: 'PUT',
    headers: { bearLang: 'view', bearId: 'caId' },
    data: { participant_id: participantId },
  });
}

export async function creatNewRoom(): Promise<any> {
  return request('/api/group_order', {
    method: 'POST',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

export async function getBusinessIdStatus(): Promise<any> {
  return request('/api/business/validation', {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

export async function getTagList(): Promise<any> {
  return request('/api/tag/ingredient', {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

export async function getAllMenus(): Promise<any> {
  return request('/api/ca/menu/fetch_all', {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

export async function getOfferingTypes(): Promise<any> {
  return request('/api/tag/offering_type', {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

//开单相关
export async function postCreateNewIndividualOrder(params: any): Promise<any> {
  const { orderData = false } = params;
  let data: any = {};

  if (orderData) {
    // 如果存在orderData 属于带菜开单 则做处理
    data.item_id = orderData.id;
    data.item_type = orderData.type;
    data.quantity = orderData.quantity;
    data.notes = orderData.notes;
    data.modifiers = orderData.modifierItems;
  }

  return request('/api/individual_order', {
    method: 'POST',
    headers: { bearLang: 'view', bearId: 'caId' },
    data,
  });
}

// 把新的orderData加到现有的购物车里
export async function postOrderDataToCurrentCart(params: any): Promise<any> {
  const {
    orderData = false,
    currentCart: { cartId, cartType, participantId },
  } = params;

  let data: any = {};

  // 先构造必填项（如果某些值为空 就不往里面传）
  // 再根据 type （dish， deal）构造他们的选填项
  if (orderData) {
    // 如果存在orderData 属于带菜开单 则做处理
    data.item_id = orderData.id;
    data.item_type = orderData.type;
    data.quantity = orderData.quantity;
  }

  // 点dish所需要的额外项
  if (orderData.notes) {
    data.notes = orderData.notes;
  }
  if (orderData.modifierItems) {
    data.modifiers = orderData.modifierItems.length > 0 ? orderData.modifierItems : [];
  }

  // 点deal所需要的额外项
  //TBD： 问后端针对redeemCode如何处理？

  //如果这是group order， 需要额外传入participant_id作为参数
  if (cartType === 'group' && participantId) data.participant_id = participantId;

  return request(`/api/${cartTypeConvert[cartType]}/add/${cartId}`, {
    method: 'PUT',
    headers: { bearLang: 'view', bearId: 'caId' },
    data,
  });
}

export async function updateOrderDataInCurrentCart(params: any): Promise<any> {
  const {
    viewerType,
    currentOrderSummary,
    currentCart: { cartId, cartType, participantId },
  } = params;

  const isCurrentViewByStaff = viewerType === 'staff' ? true : false;

  const isCurrentCartGroup = cartType === 'group' ? true : false;

  const allCurrentCartData = currentOrderSummary.map((x: any) => {
    let obj: any = {
      data: x.data.map((y: any) => ({
        rec_id: y.recId,
        item_id: y.id,
        item_type: y.type,
        quantity: y.quantity,
        notes: y.notes,
      })),
    };

    if (cartType === 'group') {
      obj.participant_id = x.participantId;
    }
    return obj;
  });

  const getClientUpdateCartData = () => {
    //如果是团餐则只取他自己改的那一点儿
    if (isCurrentCartGroup && participantId) {
      return allCurrentCartData.filter((x: any) => x.participant_id === participantId);
    }
    //不是团餐就全返回
    return allCurrentCartData;
  };

  return request(`/api/${cartTypeConvert[cartType]}/edit/${cartId}`, {
    method: 'PUT',
    headers: { bearLang: 'view', bearId: 'caId' },
    data: isCurrentViewByStaff ? allCurrentCartData : getClientUpdateCartData(),
  });
}

export async function postOrderSecondValidation(params: any): Promise<any> {
  const {
    currentCart: { cartId },
  } = params;

  return request(`/api/individual_order/validation/${cartId}`, {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

export async function deleteOrderDataInCurrentCart(params: any): Promise<any> {
  const {
    removeItem,
    currentCart: { cartId, cartType },
  } = params;

  return request(`/api/${cartTypeConvert[cartType]}/remove/${cartId}`, {
    method: 'PUT',
    headers: { bearLang: 'view', bearId: 'caId' },
    data: removeItem,
  });
}

export async function getBusinessConfigs(): Promise<any> {
  return request(`/api/business/setting`, {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}

export async function postNewRefNumber(params: any): Promise<any> {
  const {
    currentCart: { cartId, cartType },
  } = params;

  const data = {
    id: cartId,
    type: cartType,
  };

  return request(`/api/order_generate_ref_number`, {
    method: 'POST',
    headers: { bearLang: 'view', bearId: 'caId' },
    data,
  });
}

export async function postCancelStripeSession(params: any): Promise<any> {
  const {
    currentCart: { cartId, cartType },
    cartCheckoutResult: { sessionId },
  } = params;

  const data = {
    order_id: cartId,
    order_type: cartType,
    status: 'unconfirmed',
    payment_status: 'cancelled',
    cart_checkout_session_id: sessionId,
  };

  return request(`/api/stripe/cancel`, {
    method: 'POST',
    headers: { bearLang: 'view', bearId: 'caId' },
    data,
  });
}

export async function getVerifyCaOrderHistoryCode(params: any): Promise<any> {
  const {
    payload: { code, mobile },
  } = params;

  const data = {
    mobile,
    code: code,
  };

  return request(`/api/ca/history/verify/code`, {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
    params: data,
  });
}

export async function getBusinessAds(params: any): Promise<any> {
  const {} = params;

  return request(`/api/ca/advertising`, {
    method: 'GET',
    headers: { bearLang: 'view', bearId: 'caId' },
  });
}
