export interface CaSettings {
  isValidBusinessId: boolean;
  viewingBusinessId: Number;
  showFirstWelcome: boolean;

  currentCart:
    | {
        cartId: Number;
        cartType: 'individual' | 'group';
        participantId?: Number | false;
      }
    | false;
  currentCartStatus:
    | {
        status: 'disabled' | 'confirmed' | 'unconfirmed';
        pinCode?: Number;
      }
    | false;

  currentViewMenu: false | number;

  issueDishArray:
    | {
        noStock: any[];
        notInAvailableTime: any[];
        unpublished: any[];
      }
    | false;
  currentCartAppliedCoupon: any | false;
  currentCartTotalItemNumber: number;
  currentCartTotalPrice: number;
  baseCartTotalPriceFromRequst: number;

  //ca购物车支付相关
  cartCheckoutResult: {
    status: 'success' | 'fail' | 'unset';
    sessionId?: string;
  };

  //ca cart staff相关的一些
  viewerType: 'customer' | 'staff';
  refNumber: string; //used by caOrderProcess && caCart

  //caOrderHistory相关
  caOrderHistoryViewToken: string | false;

  caModal: {
    show: boolean;
    content?: 'pinCode' | 'qrCode' | 'orderHistory' | false;
  };

  tips:
    | {
        type: 'percentage';
        value: string;
        amount: number;
      }
    | false;

  allMenus: any[];
  tagList: any[];
  offeringTypeList: any[];

  showActioningMessage: boolean;
  businessConfigs: any;

  caModuleLoaded: {
    api: {
      caDishes: { self: boolean };
      caDish: { self: boolean };
      caMenus: { self: boolean };
      caMenu: { self: boolean };
      caEvents: { self: boolean };
      caEvent: { self: boolean };
      caDeals: { self: boolean };
      caDeal: { self: boolean };

      caHome: {
        recommendedDishes: boolean;
        recommendedMenus: boolean;
        recommendedEvents: boolean;
        recommendedDeals: boolean;
      };

      caInfo: { self: boolean };
      caCart: { self: boolean };
      caPreviousOrder: { self: boolean };
      caOrderProcess: { self: boolean };
      caOrderPayment: { self: boolean };
      caOrderInvoice: { self: boolean };

      allMenus: boolean;
      tag: {
        tagList: boolean;
        offeringTypeList: boolean;
      };
      staffLogin: boolean;
    };

    caDishes: boolean;
    caDish: boolean;
    caMenus: boolean;
    caMenu: boolean;
    caEvents: boolean;
    caEvent: boolean;
    caDeals: boolean;
    caDeal: boolean;

    caHome: boolean;
    caInfo: boolean;
    caCart: boolean;
    caPreviousOrder: boolean;
    caOrderProcess: boolean;
    caOrderPayment: boolean;
    caOrderInvoice: boolean;
  };
}

export default {
  isValidBusinessId: false,
  viewerType: 'customer',
  showFirstWelcome: false,
  showActioningMessage: false,

  currentCart: false,
  currentCartStatus: false,

  currentViewMenu: false,
  issueDishArray: false,

  //ca购物车支付相关
  cartCheckoutResult: {
    status: 'unset',
  },

  businessConfigs: {},

  refNumber: '',

  caModal: {
    show: false,
    content: false,
  },
  tips: false,

  caModuleLoaded: {
    api: {
      caDishes: { self: false },
      caDish: { self: false },
      caMenus: { self: false },
      caMenu: { self: false },
      caEvents: { self: false },
      caEvent: { self: false },
      caDeals: { self: false },
      caDeal: { self: false },

      caHome: {
        recommendedDishes: false,
        recommendedMenus: false,
        recommendedEvents: false,
        recommendedDeals: false,
      },

      caInfo: { self: false },
      caCart: { self: false },
      caPreviousOrder: { self: false },
      caOrderProcess: { self: false },
      caOrderPayment: { self: false },
      caOrderInvoice: { self: false },

      allMenus: false,
      tag: {
        tagList: false,
        offeringTypeList: false,
      },
      staffLogin: false,
    },

    caDishes: false,
    caDish: false,
    caMenus: false,
    caMenu: false,
    caEvents: false,
    caEvent: false,
    caDeals: false,
    caDeal: false,

    caHome: false,
    caInfo: false,
    caCart: false,
    caPreviousOrder: false,
    caOrderProcess: false,
    caOrderPayment: false,
    caOrderInvoice: false,
  },
} as CaSettings;
