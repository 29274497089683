"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function supportRef(node) {
  // Function component
  if (node.type && node.type.prototype && !node.type.prototype.render) {
    return false;
  }

  return true;
}

exports.supportRef = supportRef;