export default {
  'menu.Dashboard': 'Dashboard',
  'menu.Management': 'Menu Management',
  'menu.Management.Dishes': 'Products',
  'menu.Management.Category': 'Category',
  'menu.Campaign': 'Campaign',
  'menu.Campaign.Events': 'Events',
  'menu.Campaign.Deals': 'Deals',
  'menu.Marketing': 'Marketing',
  'menu.Marketing.Coupons': 'Coupons',
  'menu.Marketing.Marketing-Coupon': 'Coupon',
  'menu.Orders': 'Order Management',
  'menu.Orders.Bill-Process': 'Bill Process',
  'menu.Orders.Order-Processing': 'Order Processing',
  'menu.Orders.Invoice-Center': 'Invoice Center',
  'menu.Settings': 'Settings',
  'menu.Settings.Personal-Profile-Setting': 'Personal Profile Setting',
  'menu.Settings.Business-Profile-Setting': 'Business Profile Setting',
  'menu.Settings.Print': 'Business Print Setting',
  'menu.Package': 'Package',
  'menu.Premium-Services': 'Premium Services',
  'menu.Management.Dish-Detail': 'Product Detail',
  'menu.Management.Dish-Types': 'Product Types',
  'menu.Management.Dish-Types-Detail': 'Product Types Detail',
  'menu.Management.Category-Detail': 'Menu Detail',
  'menu.Management.Modifiers': 'Modifiers',
  'menu.Management.Modifier-Detail': 'Modifier Detail',
  'menu.Management.Modifier-Groups': 'Modifier Groups',
  'menu.Management.Modifier-Groups-Detail': 'Modifier Groups Detail',
  'menu.Campaign.Event-detail': 'Event Detail',
  'menu.Campaign.Deal-detail': 'Deal Detail',
  'menu.Home': 'Home',
  'menu.Analytics': 'Analytics',
  'menu.Analytics.Analytics-Sales': 'Sales Analysis',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': 'Sales Summary',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': 'Sales Products',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': 'Product Browses Summary',
  'menu.Analytics.Analytics-Visitors': 'Visitors Analysis',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': 'Access Summary',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': 'E-ticket',
  'menu.reservation': 'Reservation',
  'menu.services': 'Pro services',
  'menu.stories': 'Stories',
  'menu.support': 'Support',
  'menu.features': 'New features',
};
