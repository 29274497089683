import React from 'react';
import { message } from 'antd';
import Redirect from 'umi/redirect';
import { connect } from 'dva';
import Authorized from '@/utils/Authorized';
import { getRouteAuthority } from '@/utils/utils';
import { getMapStateToProps } from '@/utils/utils';

import { ConnectProps, ConnectState, UserModelState } from '@/models/connect';

const AuthComponent: React.FC<any> = ({
  children,
  route = {
    routes: [],
  },
  location = {
    pathname: '',
  },
  user,
}) => {
  const { currentUser } = user;
  const { routes = [] } = route;
  const isLogin = currentUser && currentUser.id ? true : false;

  return (
    <Authorized
      authority={getRouteAuthority(location.pathname, routes) || ''}
      noMatch={
        isLogin ? (
          <Redirect
            push
            to={{
              pathname: '/403',
              state: { needAuthority: true },
            }}
          />
        ) : (
          <Redirect
            push
            to={{
              pathname: '/user/login',
              state: { needLogin: true },
            }}
          />
        )
      } //TBD.
    >
      {children}
    </Authorized>
  );
};

export default connect(getMapStateToProps(['meta', 'user']))(AuthComponent);
