export interface UserSettings {
  currentUser:
    | {
        id: number;
        email: string;
        auth: string;
        status: string;
        token: any;
        tokenReceivedAt: number;
      }
    | false;
  refreshInfo?:
    | {
        userId: number;
        refreshToken: string;
      }
    | false;
  selectedPackageInfo: {
    nameKey?: string;
    price?: any;
    period?: any;
  };
  stripeInfo: { sessionId?: any };
  packageCheckoutResult?: 'unset' | 'success' | 'fail';
}

export default {
  currentUser: {},
  selectedPackageInfo: {
    price: {},
  },
  packageCheckoutResult: 'unset',
  stripeInfo: {},
} as UserSettings;
