export default {
  'menu.Dashboard': '仪盘表',
  'menu.Management': '管理',
  'menu.Management.Dishes': '菜肴',
  'menu.Management.Category': '菜单',
  'menu.Campaign': '活动',
  'menu.Campaign.Events': '事件',
  'menu.Campaign.Deals': '优惠',
  'menu.Orders': '订单',
  'menu.Orders.Bill-Process': '账单支付',
  'menu.Orders.Order-Processing': '订单处理',
  'menu.Orders.Invoice-Center': '收据中心',
  'menu.Settings': '设置',
  'menu.Settings.Personal-Profile-Setting': '个人档案设置',
  'menu.Settings.Business-Profile-Setting': '生意档案设置',
  'menu.Package': '套餐',
  'menu.Premium-Services': '高级服务',
  'menu.Management.Dish-Detail': '菜肴详情',
  'menu.Management.Dish-Types': '菜式',
  'menu.Management.Dish-Types-Detail': '菜式细节',
  'menu.Management.Category-Detail': '菜单详情',
  'menu.Management.Modifiers': '修饰符',
  'menu.Management.Modifier-Detail': '修饰符详细信息',
  'menu.Management.Modifier-Groups': '修饰符组',
  'menu.Management.Modifier-Groups-Detail': '修饰符组详细信息',
  'menu.Campaign.Event-detail': '事件详情',
  'menu.Campaign.Deal-detail': '优惠详情',
  'menu.Home': '主页',
  'menu.Settings.Print': '打印机设置',
  'menu.Marketing': '营销',
  'menu.Marketing.Coupons': '优惠券',
  'menu.Marketing.Marketing-Coupon': '优惠券',
  'menu.Analytics': '分析',
  'menu.Analytics.Analytics-Sales': '销售分析',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': '销售总结',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': '菜肴销售分析',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': '菜品浏览总结',
  'menu.Analytics.Analytics-Visitors': '访客分析',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': '访问摘要',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': '电子票务',
  'menu.reservation': '预订',
  'menu.services': '高级服务',

  'menu.stories': '公告',
  'menu.support': '客服',
  'menu.features': '新功能',
};
