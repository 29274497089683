export default {
  'menu.Dashboard': 'Instrumententafel',
  'menu.Management': 'Menü Managment',
  'menu.Management.Dishes': 'Gericht',
  'menu.Management.Category': 'Kategorie',
  'menu.Campaign': 'Kampagne',
  'menu.Campaign.Events': 'Veranstaltungen',
  'menu.Campaign.Deals': 'Angebote',
  'menu.Orders': 'Auftragsverwaltung',
  'menu.Orders.Bill-Process': 'Bill Prozess',
  'menu.Orders.Order-Processing': 'Auftragsbearbeitung',
  'menu.Orders.Invoice-Center': 'Rechnungszentrum',
  'menu.Settings': 'die Einstellungen',
  'menu.Settings.Personal-Profile-Setting': 'Persönliches Profil Rahmen',
  'menu.Settings.Business-Profile-Setting': 'Unternehmensprofil Rahmen',
  'menu.Package': 'Paket',
  'menu.Premium-Services': 'Premium service',
  'menu.Management.Dish-Detail': 'Geschirr Detail',
  'menu.Management.Dish-Types': 'Geschirrtypen',
  'menu.Management.Dish-Types-Detail': 'Geschirrtypen Detail',
  'menu.Management.Category-Detail': 'Detail-Menü',
  'menu.Management.Modifiers': 'Modifikatoren',
  'menu.Management.Modifier-Detail': 'Modifikator Detail',
  'menu.Management.Modifier-Groups': 'Modifikatorgruppen',
  'menu.Management.Modifier-Groups-Detail': 'Detail der Modifikatorgruppen',
  'menu.Campaign.Event-detail': 'Veranstaltungsdetails',
  'menu.Campaign.Deal-detail': 'Deal Details',
  'menu.Home': 'Zuhause',
  'menu.Settings.Print': 'Druckereinstellungen',
  'menu.Marketing': 'Marketing',
  'menu.Marketing.Coupons': 'Gutscheine',
  'menu.Marketing.Marketing-Coupon': 'Coupon',
  'menu.Analytics': 'Analytik',
  'menu.Analytics.Analytics-Sales': 'Verkaufsanalyse',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': 'Zusammenfassung der Verkäufe',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': 'Verkaufsgerichte',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': 'Zusammenfassung der Gerichte',
  'menu.Analytics.Analytics-Visitors': 'Besucheranalyse',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': 'Zusammenfassung aufrufen',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': 'Elektronische Tickets',
  'menu.reservation': 'Reservierung',
  'menu.services': 'Erweiterte Dienstleistungen',

  'menu.stories': 'Geschichten',
  'menu.support': 'Support',
  'menu.features': 'Neue Funktionen',
};
