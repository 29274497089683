export default {
  'menu.Dashboard': 'ダッシュボード',
  'menu.Management': 'メニュー管理',
  'menu.Management.Dishes': '料理',
  'menu.Management.Category': 'カテゴリー',
  'menu.Campaign': '運動',
  'menu.Campaign.Events': 'イベント',
  'menu.Campaign.Deals': 'お買い得情報',
  'menu.Orders': '注文管理',
  'menu.Orders.Bill-Process': '会計処理',
  'menu.Orders.Order-Processing': '注文処理',
  'menu.Orders.Invoice-Center': 'インボイスセンター',
  'menu.Settings': '設定',
  'menu.Settings.Personal-Profile-Setting': '個人プロフィールの設定',
  'menu.Settings.Business-Profile-Setting': 'ビジネスプロファイル設定',
  'menu.Package': 'パッケージ',
  'menu.Premium-Services': 'プレミアムサービス',
  'menu.Management.Dish-Detail': 'ディッシュ詳細',
  'menu.Management.Dish-Types': '料理の種類',
  'menu.Management.Dish-Types-Detail': '料理の種類の詳細',
  'menu.Management.Category-Detail': 'メニューの詳細',
  'menu.Management.Modifiers': 'モディファイア',
  'menu.Management.Modifier-Detail': 'モディファイアの詳細',
  'menu.Management.Modifier-Groups': 'モディファイアグループ',
  'menu.Management.Modifier-Groups-Detail': 'モディファイアグループの詳細',
  'menu.Campaign.Event-detail': 'イベントの詳細',
  'menu.Campaign.Deal-detail': 'ディールの詳細',
  'menu.Home': '家',
  'menu.Settings.Print': 'プリンター設定',
  'menu.Marketing': 'マーケティング',
  'menu.Marketing.Coupons': 'クーポン',
  'menu.Marketing.Marketing-Coupon': 'クーポン',
  'menu.Analytics': '分析',
  'menu.Analytics.Analytics-Sales': '売上分析',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': '販売概要',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': 'セールスディッシュ',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': '皿の閲覧の概要',
  'menu.Analytics.Analytics-Visitors': '訪問者分析',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': 'アクセスの概要',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': '電子チケット',
  'menu.reservation': '予約',
  'menu.services': '高度なサービス',

  'menu.stories': 'ストーリー',
  'menu.support': 'サポート',
  'menu.features': '新機能',
};
