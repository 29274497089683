import { useEffect, useRef } from 'react';

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

var getCircularReplacer = function getCircularReplacer() {
  var seen = new WeakSet();
  return function (key, value) {
    if (_typeof(value) === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }

      seen.add(value);
    }

    return value;
  };
};
/**
 * 一个更加安全的 stringify，可以解决循环依赖的问题
 * @param value
 */


var stringify = function stringify(value) {
  return JSON.stringify(value, getCircularReplacer());
};

var jsonCompareEquals = function jsonCompareEquals(value, nextValue) {
  try {
    return stringify(value) === stringify(nextValue);
  } catch (error) {// do something
  }

  return false;
};

function useJsonCompareMemoize(value) {
  var ref = useRef(''); // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!jsonCompareEquals(value, ref.current)) {
    ref.current = JSON.stringify(value, getCircularReplacer());
  }

  return ref.current;
}
function useDeepJSONEffect(effect, dependencies) {
  useEffect(effect, [useJsonCompareMemoize(dependencies)]);
}

export default useDeepJSONEffect;
export { stringify };
