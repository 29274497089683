export default {
  'root.dashboard': 'Dashboard',
  'root.management': 'Menu Management',
  'root.products': 'Products',
  'root.product-types': 'Product Types',
  'root.category': 'Category',
  'root.coupon': 'Coupon',
  'root.coupons': 'Coupons',
  'root.campaign': 'Campaign',
  'root.events': 'Events',
  'root.deals': 'Deals',
  'root.orders': 'Order Management',
  'root.bill-process': 'Bill Process',
  'root.order-processing': 'Order Processing',
  'root.invoice-center': 'Invoice Center',
  'root.settings': 'Settings',
  'root.personal': 'Personal Profile Setting',
  'root.business': 'Business Profile Setting',
  'root.print': 'Business Print',
  'root.package': 'Package',
  'root.premium-services': 'Premium Services',
  'root.modifiers': 'Modifiers',
  'root.modifier-groups': 'Modifier Groups',
  'root.summary': 'Summary',
  'root.product-browses': 'Product Browses',
  'root.access-summary': 'Access Summary',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': 'E-ticket',
  'root.reservation': 'Reservation',
  'root.services': 'Pro services',

  'root.stories': 'Stories',
  'root.features': 'New features',
  'root.support': 'Support',
};
