import request from '@/utils/request';

export async function refreshUserByToken(params: any) {
  const { userId, refreshToken } = params;
  return request('/api/refresh_token', {
    method: 'POST',
    headers: { bearLang: 'view' },
    data: {
      user_id: userId,
      refresh_token: refreshToken,
    },
  });
}
