import { Reducer } from 'redux';
import landingSettings, { LandingSettings } from '../../config/landingSettings';
import { Effect } from 'dva';
import { ConnectState } from '@/models/connect';
import { getLoadedModuleStatus } from '@/utils/metaModules';
import _ from 'lodash';
import { metaTranslationOfLandingGroups } from '@/constants/translationGroups';

export interface SettingModelType {
  namespace: 'landing';
  state: LandingSettings;
  effects: {
    fetchMeta: Effect;
  };
  reducers: {
    setActionButtonInfo: Reducer<LandingSettings>;
  };
}

const LandingModel: SettingModelType = {
  namespace: 'landing',
  state: landingSettings,
  effects: {
    *fetchMeta({ reload = false }, { put, all, select }) {
      if (reload) {
        yield put({
          type: 'meta/setLoadedModule',
          reload,
        });
      }
      //init the landing layout data
      /*
        1. transPkg
        2. claimInfo
        3. userDetails
       */

      const { requiredLang, claimBasic, currentUser, metaModuleLoaded } = yield select(
        (state: ConnectState) => ({
          requiredLang: state.meta.viewingLang,
          claimBasic: state.meta.claimInfo.basic,
          currentUser: state.user.currentUser,
          metaModuleLoaded: state.meta.metaModuleLoaded,
        }),
      );

      // do a check first and set a default value
      const userId = currentUser && currentUser.id ? currentUser.id : false;

      const transGroups = metaTranslationOfLandingGroups;

      // landing layout data
      const payload = { claimBasic, userId, requiredLang, transGroups };

      let getActions = getLoadedModuleStatus('landing', metaModuleLoaded, payload);

      if (getActions) {
        let actionArray: any = [];
        _.forEach(getActions, (value: any) => actionArray.push(put(value)));
        yield all(actionArray);
      }
    },
  },
  reducers: {
    setActionButtonInfo(state = landingSettings, { payload }) {
      return {
        ...state,
        actionButtonInfo: { ...payload },
      };
    },
  },
};
export default LandingModel;
