export default {
  'root.dashboard': 'ダッシュボード',
  'root.management': 'メニュー管理',
  'root.products': '料理',
  'root.product-types': 'Dish Types',
  'root.category': 'カテゴリー',
  'root.campaign': '運動',
  'root.events': 'イベント',
  'root.deals': 'お買い得情報',
  'root.orders': '注文管理',
  'root.bill-process': '会計処理',
  'root.order-processing': '注文処理',
  'root.invoice-center': 'インボイスセンター',
  'root.settings': '設定',
  'root.personal': '個人プロフィールの設定',
  'root.business': 'ビジネスプロファイル設定',
  'root.print': 'プリンター設定',
  'root.package': 'パッケージ',
  'root.premium-services': 'プレミアムサービス',
  'root.modifiers': 'モディファイア',
  'root.modifier-groups': 'モディファイアグループ',
  'root.coupon': 'クーポン',
  'root.coupons': 'クーポン',
  'root.summary': 'まとめ',
  'root.product-browses': '皿の閲覧',
  'root.access-summary': 'アクセスの概要',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': '電子チケット',
  'root.reservation': '予約',
  'root.services': '高度なサービス',

  'root.stories': 'ストーリー',
  'root.support': 'サポート',
  'root.features': '新機能',
};
