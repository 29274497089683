import { PageLoading as Loading } from '@ant-design/pro-layout';
import React from 'react';
import styles from './styles.less';
// loading components from code split
// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport

const PageLoading = () => (
  <div className={styles.loadingStyle}>
    <Loading />
  </div>
)
export default PageLoading;
