export default {
  'menu.Dashboard': 'Pannello di controllo',
  'menu.Management': 'Managment Menu',
  'menu.Management.Dishes': 'Piatto',
  'menu.Management.Category': 'Categoria',
  'menu.Campaign': 'Campagna',
  'menu.Campaign.Events': 'eventi',
  'menu.Campaign.Deals': 'offerte',
  'menu.Orders': 'Gestione degli ordini',
  'menu.Orders.Bill-Process': 'Bill processo',
  'menu.Orders.Order-Processing': "Elaborazione dell'ordine",
  'menu.Orders.Invoice-Center': 'fattura Centro',
  'menu.Settings': 'impostazioni',
  'menu.Settings.Personal-Profile-Setting': 'Impostazione del profilo personale',
  'menu.Settings.Business-Profile-Setting': 'Profilo aziendale Impostazione',
  'menu.Package': 'Pacchetto',
  'menu.Premium-Services': 'Servizi Premium',
  'menu.Management.Dish-Detail': 'particolare piatto',
  'menu.Management.Dish-Types': 'Tipi di piatto',
  'menu.Management.Dish-Types-Detail': 'Dettagli sui tipi di piatto',
  'menu.Management.Category-Detail': 'Particolare Menu',
  'menu.Management.Modifiers': 'Modificatori',
  'menu.Management.Modifier-Detail': 'Dettagli modificatore',
  'menu.Management.Modifier-Groups': 'Gruppi di modificatori',
  'menu.Management.Modifier-Groups-Detail': 'Dettagli gruppi di modificatori',
  'menu.Campaign.Event-detail': 'Descrizione degli eventi',
  'menu.Campaign.Deal-detail': 'Deal Dettaglio',
  'menu.Home': 'Casa',
  'menu.Settings.Print': 'Impostazioni della stampante',
  'menu.Marketing': 'Marketing',
  'menu.Marketing.Coupons': 'Buoni',
  'menu.Marketing.Marketing-Coupon': 'Buono',
  'menu.Analytics': 'Analitica',
  'menu.Analytics.Analytics-Sales': 'Analisi delle vendite',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': 'Riepilogo vendite',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': 'Piatti di vendita',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses':
    'Riepilogo delle ricerche del piatto',
  'menu.Analytics.Analytics-Visitors': 'Analisi dei visitatori',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': 'Accedi al Riepilogo',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': 'Biglietti elettronici',
  'menu.reservation': 'Prenotazione',
  'menu.services': 'Servizi avanzati',

  'menu.stories': 'Storie',
  'menu.support': 'Supporto',
  'menu.features': 'Nuove funzionalità',
};
