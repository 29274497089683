import _ from 'lodash';
import { isAllKeyTrue, setAllKeyFalse } from '@/utils/utils';

/**
 * set loaded modules status
 * @param od.odModuleLoaded [transPkg, editDish, ...]
 * @param od.odModuleLoaded [transPkg, editDish, ...]
 */
export const setLoadedModuleStatus = (modules: any) => {
  //NOTE: 这里面的trans是txo用的trans

  modules.odDashboard =
    isAllKeyTrue(modules.api.odDashboard) && modules.api.buisinessDetails ? true : false;

  modules.odDishes =
    isAllKeyTrue(modules.api.odDishes) && isAllKeyTrue(modules.api.tag) ? true : false;

  modules.odDish =
    isAllKeyTrue(modules.api.odDish) &&
    isAllKeyTrue(modules.api.tag) &&
    modules.api.allDishes &&
    modules.api.allMenus &&
    modules.api.allModifierGroups &&
    modules.api.buisinessDetails &&
    modules.api.trans
      ? true
      : false;

  modules.odDishTypes =
    isAllKeyTrue(modules.api.odDishTypes) &&
    modules.api.allModifierGroups &&
    modules.api.buisinessDetails
      ? true
      : false;

  modules.odDishType =
    isAllKeyTrue(modules.api.odDishType) &&
    modules.api.allModifierGroups &&
    modules.api.buisinessDetails
      ? true
      : false;

  modules.odMenus = isAllKeyTrue(modules.api.odMenus) ? true : false;
  modules.odMenu =
    isAllKeyTrue(modules.api.odMenu) &&
    isAllKeyTrue(modules.api.tag) &&
    modules.api.allDishes &&
    modules.api.allMenus &&
    modules.api.trans
      ? true
      : false;

  modules.odModifiers =
    isAllKeyTrue(modules.api.odModifiers) &&
    modules.api.buisinessDetails &&
    modules.api.allModifierGroups
      ? true
      : false;
  modules.odModifier =
    isAllKeyTrue(modules.api.odModifier) &&
    modules.api.buisinessDetails &&
    modules.api.allModifierGroups
      ? true
      : false;
  modules.odModifications =
    isAllKeyTrue(modules.api.odModifications) && modules.api.buisinessDetails ? true : false;
  modules.odModification =
    isAllKeyTrue(modules.api.odModification) && modules.api.buisinessDetails ? true : false;

  modules.odCoupons = isAllKeyTrue(modules.api.odCoupons) ? true : false;
  modules.odCoupon =
    isAllKeyTrue(modules.api.odCoupon) && modules.api.allDishes && modules.api.allMenus
      ? true
      : false;

  modules.odEvents = isAllKeyTrue(modules.api.odEvents) ? true : false;
  modules.odEvent = isAllKeyTrue(modules.api.odEvent) && modules.api.trans ? true : false;

  modules.odDeals = isAllKeyTrue(modules.api.odDeals) ? true : false;
  modules.odDeal =
    isAllKeyTrue(modules.api.odDeal) &&
    isAllKeyTrue(modules.api.tag) &&
    modules.api.allDishes &&
    modules.api.allMenus &&
    modules.api.trans
      ? true
      : false;

  modules.odSettingBusiness =
    isAllKeyTrue(modules.api.odSettingBusiness) &&
    modules.api.trans &&
    isAllKeyTrue(modules.api.tag)
      ? true
      : false;

  modules.odSettingPrint = isAllKeyTrue(modules.api.odSettingPrint) ? true : false;

  modules.odOrderProcessing =
    isAllKeyTrue(modules.api.odOrderProcessing) && modules.api.buisinessDetails ? true : false;
  modules.odOrderBill =
    isAllKeyTrue(modules.api.odOrderBill) && modules.api.buisinessDetails ? true : false;
  modules.odOrderInvoice =
    isAllKeyTrue(modules.api.odOrderInvoice) && modules.api.buisinessDetails ? true : false;

  modules.odAnalyticsSalesSummary =
    isAllKeyTrue(modules.api.odAnalyticsSalesSummary) && modules.api.buisinessDetails
      ? true
      : false;

  return modules;
};

/**
 *
 * @param reload trans, business, dish-update
 * @param currentLoadedModule current odModuleLoaded model
 *
 */
export const reloadModuleStatus = (reload: any, currentLoadedModule: any) => {
  const blankApi = {
    trans: false,
    tag: {
      tagList: false,
      offeringTypeList: false,
    },
    buisinessDetails: false,
    allDishes: false,
    allMenus: false,
    allModifierGroups: false,

    odDashboard: {
      self: false,
    },
    odDishes: {
      self: false,
    },
    odDish: {
      self: false,
    },
    odDishTypes: {
      self: false,
    },
    odDishType: {
      self: false,
    },
    odMenus: {
      self: false,
    },
    odMenu: {
      self: false,
    },
    odCoupons: {
      self: false,
    },

    odCoupon: {
      self: false,
    },
    odModifiers: {
      self: false,
    },
    odModifier: {
      self: false,
    },
    odModifications: {
      self: false,
    },
    odModification: {
      self: false,
      extra: false,
    },
    odEvents: { self: false },
    odEvent: { self: false },
    odDeals: { self: false },
    odDeal: {
      self: false,
    },
    odSettingBusiness: {
      self: false,
    },
    odSettingPrint: {
      self: false,
    },
    odOrderProcessing: {
      self: false,
    },
    odOrderBill: {
      self: false,
    },
    odOrderInvoice: {
      self: false,
    },
    odAnalyticsSalesSummary: {
      self: false,
    },
  };

  if (reload === 'all') {
    //api全换
    currentLoadedModule = {
      ...currentLoadedModule,
      api: blankApi,
    };
  } else if (reload === 'view-trans') {
    //如果是view-trans 则reload所有跟view-trans相关的部分
    //只保留edit-trans
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...blankApi,
        trans: currentLoadedModule.api.trans,
      },
    };
  } else if (reload === 'edit-trans') {
    //如果是edit-trans 则reload所有跟edit-trans相关的部分
    //改 trans && edit & business-details
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...currentLoadedModule.api,
        trans: false,
        buisinessDetails: false,
        odDish: blankApi.odDish,
        odMenu: blankApi.odMenu,
        odEvent: blankApi.odEvent,
        odDeal: blankApi.odDeal,
        odSettingBusiness: blankApi.odSettingBusiness,
      },
    };
  } else if (reload === 'business-switch') {
    //如果是business 则reload所有跟data相关的部分
    //改 edit && pair && list && all
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...currentLoadedModule.api,
        ..._.omit(blankApi, ['trans']),
      },
    };
  } else if (reload === 'order-refresh') {
    //如果是order类的变动 则reload所有和order有关的部分
    currentLoadedModule = {
      ...currentLoadedModule,
      api: {
        ...currentLoadedModule.api,
        odOrderProcessing: blankApi.odOrderProcessing,
        odOrderBill: blankApi.odOrderBill,
        odOrderInvoice: blankApi.odOrderInvoice,
      },
    };
  } else {
    /*
    reload :
        odDish: 'odDish-update' | 'odDish-switch',
        odMenu: 'odMenu-update' | 'odMenu-switch',
        odEvent: 'odEvent-update' | 'odEvent-switch'',
        odDeal: 'odDeal-update' | 'odDeal-switch',
        odModifier: --
        odModification: --
        odDishType: --

        odDishes: 'odDishes-clear' | 'odDishes-create',
        odMenues: 'odMenus-clear' | 'odMenus-create',
        odEvents: 'odEvents-clear' | 'odEvents-create',
        odDeals: 'odDeals-clear' | 'odDeals-create',
        odModifiers: --
        odModifications: --
        odDishTypes: --

        reloadModule: odDish/odMenu/odEvent/odDeal odDishes/odMenus/odEvents/odDeals
        actionMode: update/switch  clear/create

    */
    const allModules = [
      'odDashboard',
      'odDishes',
      'odDish',
      'odMenus',
      'odMenu',
      'odCoupons',
      'odCoupon',
      'odModifiers',
      'odModifier',
      'odModifications',
      'odModification',
      'odDishTypes',
      'odDishType',
      'odEvents',
      'odEvent',
      'odDeals',
      'odDeal',
      'odSettingBusiness',
      'odOrderProcessing',
      'odOrderBill',
      'odOrderInvoice',
    ];
    const actionArray = ['update', 'switch', 'clear', 'create'];

    //这里做一下extra资源的处理
    const modelsNeedToReloadModificationExtraWhenUpdated = ['odModifier', 'odModifiers'];
    const modelsNeedToReloadModificationExtraWhenCreated = ['odModifiers'];

    //TBD 这个地方暂时只用最简单的情况 复杂的情况再讨论
    const modelsNeedToReloadAllDishesWhenUpdated = ['odDish', 'odDishes', 'odCoupon'];
    const modelsNeedToReloadAllDishesWhenCreated = ['odDishes'];

    const modelsNeedToReloadAllMenusWhenUpdated = ['odMenu', 'odMenus', 'odCoupon'];
    const modelsNeedToReloadAllMenusWhenCreated = ['odMenus'];

    const modelsNeedToReloadTagWhenUpdated = ['odDishType', 'odDishTypes'];
    const modelsNeedToReloadTagWhenCreated = ['odDishTypes'];

    const modelsNeedToReloadAllModifierGroupsWhenUpdated = [
      'odModifier',
      'odModifiers',
      'odModifications',
      'odModification',
    ];
    const modelsNeedToReloadAllModifierGroupsWhenCreated = ['odModifications'];

    const modelsNeedToReloadBusinessDetailsWhenUpdated = ['odSettingBusiness'];

    const modelsNeedToReloadListSelivesWhenUpdated = [
      'odDish',
      'odMenu',
      'odEvent',
      'odDeal',
      'odModifier',
      'odModification',
      'odDishType',
      'odCoupon',
    ];

    const [reloadModule, actionMode] = reload.split('-');

    if (allModules.includes(reloadModule) && actionArray.includes(actionMode)) {
      //update | switch | clear | create 某一项 则它的self要首先更改为false
      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          [reloadModule]: { ...currentLoadedModule.api[reloadModule], self: false },
        },
      };
    }

    //update | create 动作对应特定的model时 reload odModification的extra -- all modifier_items
    if (
      (actionMode === 'update' &&
        modelsNeedToReloadModificationExtraWhenUpdated.includes(reloadModule)) ||
      (actionMode === 'create' &&
        modelsNeedToReloadModificationExtraWhenCreated.includes(reloadModule))
    ) {
      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          odModification: { self: false, extra: false },
        },
      };
    }

    //处理需要reload list selves的情况
    if (
      actionMode === 'update' &&
      modelsNeedToReloadListSelivesWhenUpdated.includes(reloadModule)
    ) {
      const singleToListNameConvert = {
        odDish: 'odDishes',
        odMenu: 'odMenus',
        odEvent: 'odEvents',
        odDeal: 'odDeals',
        odModifier: 'odModifiers',
        odModification: 'odModifications',
        odDishType: 'odDishTypes',
        odCoupon: 'odCoupons',
      };

      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          [singleToListNameConvert[reloadModule]]: {
            ...currentLoadedModule.api[singleToListNameConvert[reloadModule]],
            self: false,
          },
        },
      };
    }

    //处理需要reload tag的情况
    if (
      (actionMode === 'update' && modelsNeedToReloadTagWhenUpdated.includes(reloadModule)) ||
      (actionMode === 'create' && modelsNeedToReloadTagWhenCreated.includes(reloadModule))
    ) {
      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          tag: {
            tagList: false,
            offeringTypeList: false,
          },
        },
      };
    }

    //处理需要reload allDishes的情况
    if (
      (actionMode === 'update' && modelsNeedToReloadAllDishesWhenUpdated.includes(reloadModule)) ||
      (actionMode === 'create' && modelsNeedToReloadAllDishesWhenCreated.includes(reloadModule))
    ) {
      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          allDishes: false,
        },
      };
    }

    //处理需要reload allMenus的情况
    if (
      (actionMode === 'update' && modelsNeedToReloadAllMenusWhenUpdated.includes(reloadModule)) ||
      (actionMode === 'create' && modelsNeedToReloadAllMenusWhenCreated.includes(reloadModule))
    ) {
      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          allMenus: false,
        },
      };
    }
    //处理需要reload allModifierGroups的情况
    if (
      (actionMode === 'update' &&
        modelsNeedToReloadAllModifierGroupsWhenUpdated.includes(reloadModule)) ||
      (actionMode === 'create' &&
        modelsNeedToReloadAllModifierGroupsWhenCreated.includes(reloadModule))
    ) {
      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          allModifierGroups: false,
        },
      };
    }

    //处理需要reload businessDetails的情况
    if (
      actionMode === 'update' &&
      modelsNeedToReloadBusinessDetailsWhenUpdated.includes(reloadModule)
    ) {
      currentLoadedModule = {
        ...currentLoadedModule,
        api: {
          ...currentLoadedModule.api,
          buisinessDetails: false,
        },
      };
    }
  }
  return currentLoadedModule;
};
/**
 *
 * @param moduleKey indicates which module you want to check
 * @param odModuleLoaded current odModuleLoaded model
 * @param state ConnectState data - object contains meta data
 *
 * Possible Arguments in "state"
 * editingId
 */

export const getLoadedModuleStatus = (
  moduleKey: string,
  odModuleLoaded: any,
  state: any = false,
) => {
  const { editingId = false, businessId = false } = state;

  let actions = [];

  // const singleModelArray = ['odDish', 'odMenu', 'odDeal', 'odEvent', 'business'];
  // const listModelArray = ['odDishes', 'odMenus', 'odDeals', 'odEvents'];

  const allModules = [
    'odDashboard',
    'odDishes',
    'odDish',
    'odDishTypes',
    'odDishType',
    'odMenus',
    'odMenu',
    'odCoupons',
    'odCoupon',
    'odModifiers',
    'odModifier',
    'odEvents',
    'odEvent',
    'odDeals',
    'odDeal',
    'odSettingBusiness',
    'odSettingPrint',
    'odOrderProcessing',
    'odOrderBill',
    'odOrderInvoice',
  ];

  const modelsNeedToSendSelfId = [
    'odDish',
    'odMenu',
    'odModifier',
    'odCoupon',
    'odDeal',
    'odEvent',
    'odDashboard',
    'odSettingBusiness',
    'odModification',
    'odDishType',
    'odAnalyticsSalesSummary',
  ];
  const modelsNoNeedToSendSelfId = [
    'odCoupons',
    'odDishes',
    'odMenus',
    'odModifiers',
    'odDeals',
    'odEvents',
    'odOrderBill',
    'odOrderProcessing',
    'odOrderInvoice',
    'odSettingPrint',
    'odModifications',
    'odDishTypes',
  ];
  const modelsNeedToLoadExtraWithoutId = ['odModification'];

  const modelsNeedToLoadTrans = ['odDish', 'odMenu', 'odDeal', 'odEvent', 'odSettingBusiness'];

  const modelsNeedToLoadAllDishes = ['odDish', 'odMenu', 'odDeal', 'odCoupon'];
  const modelsNeedToLoadAllMenus = ['odDish', 'odMenu', 'odDeal', 'odCoupon'];
  const modelsNeedToLoadAllModifierGroups = [
    'odDish',
    'odDishType',
    'odDishTypes',
    'odModifier',
    'odModifiers',
  ];

  const modelsNeedToLoadTag = ['odDish', 'odDishes', 'odMenu', 'odDeal', 'odSettingBusiness'];
  const modelsNeedToLoadBusinessDetails = [
    'odDish',
    'odDishType',
    'odDishTypes',
    'odModifier',
    'odModifiers',
    'odModification',
    'odModifications',
    'odDashboard',
    'odOrderProcessing',
    'odOrderBill',
    'odOrderInvoice',
    'odAnalyticsSalesSummary',
  ];
  /**
   * 先检查哪一个模块需要取od-meta
   * 它对应的数据都存在的话 则调用fetch 去获取需要的数据
   */

  //检查有没有load过trans, 没有则load
  if (modelsNeedToLoadTrans.includes(moduleKey) && !odModuleLoaded.api.trans) {
    actions.push({
      type: 'od/fetchTranslations',
    });
  }

  //检查有没有load过allMenus, 没有则load
  if (
    modelsNeedToLoadBusinessDetails.includes(moduleKey) &&
    !odModuleLoaded.api.buisinessDetails &&
    businessId
  ) {
    actions.push({
      type: 'od/fetchEditingBuisinessDetails',
      businessId,
    });
  }

  //检查有没有load过allDishes, 没有则load
  if (modelsNeedToLoadAllDishes.includes(moduleKey) && !odModuleLoaded.api.allDishes) {
    actions.push({
      type: 'od/fetchAllDishes',
    });
  }

  //检查有没有load过allMenus, 没有则load
  if (modelsNeedToLoadAllMenus.includes(moduleKey) && !odModuleLoaded.api.allMenus) {
    actions.push({
      type: 'od/fetchAllMenus',
    });
  }

  if (
    modelsNeedToLoadAllModifierGroups.includes(moduleKey) &&
    !odModuleLoaded.api.allModifierGroups
  ) {
    actions.push({
      type: 'od/fetchAllModifierGroups',
    });
  }

  //检查有没有load过Tag, 没有则load
  if (modelsNeedToLoadTag.includes(moduleKey)) {
    if (!odModuleLoaded.api.tag.tagList) {
      actions.push({
        type: 'od/fetchTagList',
      });
    }
    if (!odModuleLoaded.api.tag.offeringTypeList) {
      actions.push({
        type: 'od/fetchOfferingTypeList',
      });
    }
  }

  //检查有没有load过self, 没有则load
  if (modelsNeedToSendSelfId.includes(moduleKey) && editingId) {
    //需要给ID
    if (!odModuleLoaded.api[moduleKey].self) {
      actions.push({
        type: 'fetch',
        editingId,
      });
    }
  }

  if (modelsNoNeedToSendSelfId.includes(moduleKey) && !odModuleLoaded.api[moduleKey].self) {
    //不需要给ID
    actions.push({
      type: 'fetch',
    });
  }

  if (modelsNeedToLoadExtraWithoutId.includes(moduleKey) && !odModuleLoaded.api[moduleKey].extra) {
    //不需要给ID
    actions.push({
      type: 'fetchExtra',
    });
  }

  return actions.length === 0 ? false : actions;
};

/**
 * 这个函数在od中最后仅仅修改fetchMeta reloadModule，不修改状态机本身
 *
 */
export const getReloadModule = (
  showActioningMessage: boolean,
  spaceName: string,
  nextMove: 'redirect' | 'stay' | false = false,
  payload: string | false = false,
) => {
  let action: any = {
    type: 'od/setReloadAction',
    showActioningMessage,
    spaceName,
  };

  if (nextMove === 'redirect') {
    action = { ...action, redirectUrl: payload };
  } else if (nextMove === 'stay') {
    action = { ...action, reloadType: payload };
  }

  return action;
};

//这个函数和上面的完全一样 就是去掉了od的spaceName
//减少warning用的
export const getReloadModuleInOd = (
  showActioningMessage: boolean,
  spaceName: string,
  nextMove: 'redirect' | 'stay' | false = false,
  payload: string | false = false,
) => {
  let action: any = {
    type: 'setReloadAction',
    showActioningMessage,
    spaceName,
  };

  if (nextMove === 'redirect') {
    action = { ...action, redirectUrl: payload };
  } else if (nextMove === 'stay') {
    action = { ...action, reloadType: payload };
  }

  return action;
};
