import { Reducer } from 'redux';
import utilSettings, { UtilSettings } from '../../config/utilSettings';
import _ from 'lodash';
import { Effect, Subscription } from 'dva';
import { message } from 'antd';
import { postSendHistoryCode } from '@/services/util';

export interface ModelType {
  namespace: string;
  state: UtilSettings;
  effects: {
    //ca相关
    sendCaOrderHistoryVerification: Effect;
  };
  reducers: {
    //socker io
    setSocketItem: Reducer<UtilSettings>;
    //incoming message相关
    setWaitingOrders: Reducer<UtilSettings>;
    clearWaitingOrders: Reducer<UtilSettings>;
    setWaitingInvoices: Reducer<UtilSettings>;
    clearWaitingInvoices: Reducer<UtilSettings>;
  };
}

const UtilModel: ModelType = {
  namespace: 'util',
  state: utilSettings,
  effects: {
    *sendCaOrderHistoryVerification({ payload }, { call, put }) {
      const response = yield call(postSendHistoryCode, { payload });
      if (response.success) {
        message.success(response.message);
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
  },
  reducers: {
    //socker io
    setSocketItem(state = utilSettings, { socketItem = false }) {
      //如果没有传进来socketItem --说明是要清空 (并且此时store里面存了一个已经连接的)
      //则在清空之前告诉一下服务器断开连接
      if (!socketItem && state.socketItem && state.socketItem.connected) {
        state.socketItem.disconnect();
      }
      return {
        ...state,
        socketItem,
      };
    },
    //incoming message相关
    setWaitingOrders(state = utilSettings, { waitingOrders = false }) {
      return {
        ...state,
        waitingOrders,
      };
    },
    clearWaitingOrders(state = utilSettings, {}) {
      return {
        ...state,
        waitingOrders: false,
      };
    },
    setWaitingInvoices(state = utilSettings, { waitingInvoices = false }) {
      return {
        ...state,
        waitingInvoices,
      };
    },
    clearWaitingInvoices(state = utilSettings, {}) {
      return {
        ...state,
        waitingInvoices: false,
      };
    },
  },
};

export default UtilModel;
