import request from '@/utils/request';
import queryString from 'query-string';

export async function getTranslations(params: any): Promise<any> {
  const { transGroups } = params;
  return request('/api/translations', {
    method: 'GET',
    headers: { bearLang: 'view' },
    params: { groups: transGroups },
    paramsSerializer: params => {
      const serealizedString = params['groups']
        ? queryString.stringify(params, { arrayFormat: 'index' })
        : queryString.stringify(params);
      return serealizedString;
    },
  });
}

export async function getClaimBusinessInfo(params: any): Promise<any> {
  const { claimBusinessId, claimToken } = params;
  return request('/api/business_verify_token', {
    method: 'GET',
    headers: { bearLang: 'view' },
    params: { b_id: claimBusinessId, token: claimToken },
  });
}

export async function getCurrentUserdetails(params: any) {
  const { userId } = params;
  return request(`/api/user/${userId}?expand[0]=business`, {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth' },
  });
}

export async function getCurrentEditingBusinessPackages(): Promise<any> {
  return request(`/api/package`, {
    method: 'GET',
    headers: { bearLang: 'view', bearToken: 'auth', bearId: 'bId' },
  });
}
