export default {
  'root.dashboard': '계기반',
  'root.management': '메뉴 Managment를',
  'root.products': '요리',
  'root.product-types': 'Dish Types',
  'root.category': '범주',
  'root.campaign': '운동',
  'root.events': '이벤트',
  'root.deals': '거래',
  'root.orders': '주문 관리',
  'root.bill-process': '빌 프로세스',
  'root.order-processing': '주문 처리',
  'root.invoice-center': '송장 센터',
  'root.settings': '설정',
  'root.personal': '개인 프로필 설정',
  'root.business': '비즈니스 프로파일 설정',
  'root.print': '프린터 설정',
  'root.package': '꾸러미',
  'root.premium-services': '프리미엄 서비스',
  'root.modifiers': '수정 자',
  'root.modifier-groups': '수정 자 그룹',
  'root.coupon': '쿠폰',
  'root.coupons': '쿠폰',
  'root.summary': '요약',
  'root.product-browses': '요리 찾아보기',
  'root.access-summary': '액세스 요약',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': '전자 티켓',
  'root.reservation': '예약',
  'root.services': '고급 서비스',

  'root.stories': '이야기',
  'root.support': '지원',
  'root.features': '새로운 기능',
};
