'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = {
  // Options.jsx
  items_per_page: '/ 쪽',
  jump_to: '이동하기',
  jump_to_confirm: '확인하다',
  page: '',

  // Pagination.jsx
  prev_page: '이전 페이지',
  next_page: '다음 페이지',
  prev_5: '이전 5 페이지',
  next_5: '다음 5 페이지',
  prev_3: '이전 3 페이지',
  next_3: '다음 3 페이지'
};
module.exports = exports['default'];