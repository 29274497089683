export default {
  'menu.Dashboard': '계기반',
  'menu.Management': '메뉴 Managment를',
  'menu.Management.Dishes': '요리',
  'menu.Management.Category': '범주',
  'menu.Campaign': '운동',
  'menu.Campaign.Events': '이벤트',
  'menu.Campaign.Deals': '거래',
  'menu.Orders': '주문 관리',
  'menu.Orders.Bill-Process': '빌 프로세스',
  'menu.Orders.Order-Processing': '주문 처리',
  'menu.Orders.Invoice-Center': '송장 센터',
  'menu.Settings': '설정',
  'menu.Settings.Personal-Profile-Setting': '개인 프로필 설정',
  'menu.Settings.Business-Profile-Setting': '비즈니스 프로파일 설정',
  'menu.Package': '꾸러미',
  'menu.Premium-Services': '프리미엄 서비스',
  'menu.Management.Dish-Detail': '요리 상세',
  'menu.Management.Dish-Types': '접시 유형',
  'menu.Management.Dish-Types-Detail': '요리 유형 세부 정보',
  'menu.Management.Category-Detail': '메뉴 세부 정보',
  'menu.Management.Modifiers': '수정 자',
  'menu.Management.Modifier-Detail': '수정 자 세부 정보',
  'menu.Management.Modifier-Groups': '수정 자 그룹',
  'menu.Management.Modifier-Groups-Detail': '수정 자 그룹 세부 정보',
  'menu.Campaign.Event-detail': '이벤트 정보',
  'menu.Campaign.Deal-detail': '거래 세부 정보',
  'menu.Home': '집',
  'menu.Settings.Print': '프린터 설정',
  'menu.Marketing': '마케팅',
  'menu.Marketing.Coupons': '쿠폰',
  'menu.Marketing.Marketing-Coupon': '쿠폰',
  'menu.Analytics': '해석학',
  'menu.Analytics.Analytics-Sales': '판매 분석',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': '판매 요약',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': '판매 요리',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': '요리 탐색 요약',
  'menu.Analytics.Analytics-Visitors': '방문자 분석',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': '액세스 요약',

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': '전자 티켓',
  'menu.reservation': '예약',
  'menu.services': '고급 서비스',

  'menu.stories': '이야기',
  'menu.support': '지원',
  'menu.features': '새로운 기능',
};
