import pay from '@/pages/user/package/pay';
import request from '@/utils/request';

export async function postSendHistoryCode(params: any): Promise<any> {
  const {
    payload: { mobileNumber, token },
  } = params;

  const data = {
    mobile: mobileNumber,
    captcha_code: token,
  };

  return request(`/api/ca/history/code `, {
    method: 'POST',
    headers: { bearLang: 'view', bearId: 'caId' },
    data,
  });
}
