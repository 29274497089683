import { Dispatch } from 'redux';
// import { response } from 'express';
/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { readUserInfoFromStorage } from '@/utils/user';
import { notification, message } from 'antd';
import router from 'umi/router';
import request, { extend } from 'umi-request';
import { LANGUAGELABLE } from '@/constants/Languages';
import { validBusinessIdFormat } from '@/constants/ValidNumberFormat';
import _ from 'lodash';

const codeMessage = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  204: 'No Content',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  406: 'Variables Not Acceptable',
  410: 'Gone',
  422: 'Unprocessable Entity',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',

  //personal defined code for successs
  240: 'Returned Failed Validation',
  241: 'Returned Registration Uncompleted',
  242: 'Returned Registration Completed',
  244: 'Business already claimed',

  //personal defined code for successs
  419: 'Page Expired',
};

/**
 * 处理需要bear token的请求
 */
request.interceptors.request.use((url, options: any) => {
  let lang: any = false;
  let accessToken: any = false;
  let bId: any = false;

  let headerJson: any = {
    ...options.headers,
  };

  if (options.headers && options.headers.bearLang === 'view') {
    lang =
      localStorage &&
      localStorage.getItem('popsup-view-lang') &&
      LANGUAGELABLE.includes(localStorage.getItem('popsup-view-lang') as string)
        ? localStorage.getItem('popsup-view-lang')
        : 'en_AU';
  } else if (options.headers && options.headers.bearLang === 'edit') {
    lang =
      localStorage &&
      localStorage.getItem('popsup-edit-lang') &&
      LANGUAGELABLE.includes(localStorage.getItem('popsup-edit-lang') as string)
        ? localStorage.getItem('popsup-edit-lang')
        : 'en_AU';
  }

  if (options.headers && options.headers.bearId === 'bId') {
    bId =
      sessionStorage &&
      sessionStorage.getItem('popsup-od-editing-business-info') &&
      JSON.parse(sessionStorage.getItem('popsup-od-editing-business-info') as string).b_id
        ? JSON.parse(sessionStorage.getItem('popsup-od-editing-business-info') as string).b_id
        : false;
  } else if (options.headers && options.headers.bearId === 'caId') {
    bId =
      localStorage &&
      localStorage.getItem('popsup-ca-viewing-id') &&
      validBusinessIdFormat.test(localStorage.getItem('popsup-ca-viewing-id') as string)
        ? localStorage.getItem('popsup-ca-viewing-id')
        : false;
  }

  if (options.headers && options.headers.bearToken === 'auth') {
    //从session 和 local里面分别找token
    const userInfo = readUserInfoFromStorage();
    //取到localStorage 或者 sessionStorage的 userInfo
    accessToken = userInfo && userInfo.token.access_token ? userInfo.token.access_token : false;
  }

  if (lang) {
    headerJson = _.omit({ ...headerJson, lang }, 'bearLang');
  }
  if (bId) {
    headerJson = _.omit({ ...headerJson, bid: bId }, 'bearId');
  }
  if (accessToken) {
    headerJson = _.omit({ ...headerJson, Authorization: `Bearer ${accessToken}` }, 'bearToken');
  }

  return {
    url: url,
    options: {
      ...options,
      headers: headerJson,
    },
  };
});

/**
 * 异常处理程序 (处理 No/403/404/404/500)
 */
const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  const { status, url } = response;

  if (!response) {
    // no response
    notification.error({
      message: 'Network Wrong',
      description: 'Can not connected to the sever',
    });

    router.push('/404');
    throw error;
  }

  if (status === 403 || status === 404 || status === 500) {
    //Redirect to certain pages.
    notification.error({
      message: `${codeMessage[status]} ${status}: ${url}`,
    });

    router.push(`/${status}`);
    throw error;
  }

  return response;
};

/**
 * 处理返回对象error 400
 */
request.interceptors.response.use(async (response, options) => {
  const { responseType } = options;
  const { status, url } = response;

  if (responseType && responseType !== 'json') {
    // type not json, no handle
    return response;
  }

  if (Number(status.toString()[0]) === 2) {
    // 2开头的就表示成功 (200 / 240 在里面处理)
    return response;
  }
  if (Number(status.toString()[0]) !== 2) {
    //不是2的话 有可能是3xx 4xx 5xx 这样的话就显示message就行了
    let data = null;
    try {
      data = await response.clone().json();
    } catch (error) {
      console.error(error);
      return response;
    }

    const errMsg = data.message ? data.message : codeMessage[status];
    message.error(errMsg);
  }

  return response;
});

const newRequest = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

export default newRequest;
