export default {
  'root.dashboard': 'Tableau de bord',
  'root.management': 'root Managment',
  'root.products': 'Plat',
  'root.product-types': 'Dish Types',
  'root.category': 'Catégorie',
  'root.campaign': 'Campagne',
  'root.events': 'Événements',
  'root.deals': 'Offres',
  'root.orders': 'La gestion des commandes',
  'root.bill-process': 'processus du projet de loi',
  'root.order-processing': 'Commande en cours de traitement',
  'root.invoice-center': 'facture Centre',
  'root.settings': 'Réglages',
  'root.personal': 'Réglage Profil personnel',
  'root.business': "Profil de l'entreprise Réglage",
  'root.print': "Paramètres de l'imprimante",
  'root.package': 'Paquet',
  'root.premium-services': 'services premium',
  'root.modifiers': 'Modificatrice',
  'root.modifier-groups': 'Groupes de modificateurs',
  'root.coupon': 'Coupon',
  'root.coupons': 'Bons de réduction',
  'root.summary': 'résumé',
  'root.product-browses': 'Parcours de plats',
  'root.access-summary': "Résumé d'accès",
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': 'Billets électroniques',
  'root.reservation': 'Réservation',
  'root.services': 'Services avancés',

  'root.stories': 'Histoires',
  'root.support': 'Support',
  'root.features': 'Nouvelles fonctionnalités',
};
