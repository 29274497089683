export default {
  'root.dashboard': '儀盤表',
  'root.management': '管理',
  'root.products': '菜餚',
  'root.product-types': 'Dish Types',
  'root.category': '菜單',
  'root.campaign': '活動',
  'root.events': '事件',
  'root.deals': '優惠',
  'root.orders': '訂單',
  'root.bill-process': '帳單支付',
  'root.order-processing': '訂單處理',
  'root.invoice-center': '收據中心',
  'root.settings': '設置',
  'root.personal': '個人檔案設置',
  'root.business': '生意檔案設置',
  'root.package': '套餐',
  'root.print': '打印機設置',
  'root.premium-services': '高級服務',
  'root.modifiers': '修飾符',
  'root.modifier-groups': '修飾符組',
  'root.coupon': '優惠券',
  'root.coupons': '優惠券',
  'root.summary': '總結',
  'root.product-browses': '菜品瀏覽',
  'root.access-summary': '訪問摘要',
  'root.booking': 'Booking System',
  'root.foodnote': 'Foodnote System',

  'root.tickets': '電子票務',
  'root.reservation': '預訂',
  'root.services': '高級服務',

  'root.stories': '公告',
  'root.support': '客服',
  'root.features': '新功能',
};
