export default {
  'menu.Dashboard': 'Tableau de bord',
  'menu.Management': 'Menu Managment',
  'menu.Management.Dishes': 'Plat',
  'menu.Management.Category': 'Catégorie',
  'menu.Campaign': 'Campagne',
  'menu.Campaign.Events': 'Événements',
  'menu.Campaign.Deals': 'Offres',
  'menu.Orders': 'La gestion des commandes',
  'menu.Orders.Bill-Process': 'processus du projet de loi',
  'menu.Orders.Order-Processing': 'Commande en cours de traitement',
  'menu.Orders.Invoice-Center': 'facture Centre',
  'menu.Settings': 'Réglages',
  'menu.Settings.Personal-Profile-Setting': 'Réglage Profil personnel',
  'menu.Settings.Business-Profile-Setting': "Profil de l'entreprise Réglage",
  'menu.Package': 'Paquet',
  'menu.Premium-Services': 'services premium',
  'menu.Management.Dish-Detail': 'Détail plat',
  'menu.Management.Dish-Types': 'Types de plats',
  'menu.Management.Dish-Types-Detail': 'Détail des types de plat',
  'menu.Management.Category-Detail': 'Menu Détail',
  'menu.Management.Modifiers': 'Modificateurs',
  'menu.Management.Modifier-Detail': 'Détail du modificateur',
  'menu.Management.Modifier-Groups': 'Groupes de modificateurs',
  'menu.Management.Modifier-Groups-Detail': 'Détail des groupes de conditions commerciales',
  'menu.Campaign.Event-detail': "Détails de l'événement",
  'menu.Campaign.Deal-detail': 'Détail Deal',
  'menu.Home': 'Accueil',
  'menu.Settings.Print': "Paramètres de l'imprimante",
  'menu.Marketing': 'Commercialisation',
  'menu.Marketing.Coupons': 'Bons de réduction',
  'menu.Marketing.Marketing-Coupon': 'Coupon',
  'menu.Analytics': 'Analytique',
  'menu.Analytics.Analytics-Sales': 'Analyse des ventes',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Summary': 'Résumé des ventes',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-Dishes': 'Plats de vente',
  'menu.Analytics.Analytics-Sales.Analytics-Sales-DishBrowses': 'Résumé des parcours de plats',
  'menu.Analytics.Analytics-Visitors': 'Analyse des visiteurs',
  'menu.Analytics.Analytics-Visitors.Analytics-Visitors-AccessSummary': "Résumé d'accès",

  'menu.foodnotePro': 'Foodnote.Pro',
  'menu.foodnotePlus': 'Foodnote.Plus',

  'menu.tickets': 'Billets électroniques',
  'menu.reservation': 'Réservation',
  'menu.services': 'Services avancés',

  'menu.stories': 'Histoires',
  'menu.support': 'Support',
  'menu.features': 'Nouvelles fonctionnalités',
};
