interface orderType {
  id: number;
  orderType: 'individual' | 'group';
}

interface invoiceType {
  id: number;
}

export interface UtilSettings {
  waitingOrders:
    | {
        action: 'select';
        spaceName: 'odOrderProcessing' | 'odOrderBill';
        order: orderType;
      }
    | false;
  waitingInvoices:
    | {
        action: 'show';
        spaceName: 'odOrderInvoice';
        invoice: invoiceType;
      }
    | false;

  //socket io
  socketItem: any | false;

  //ca -- SMS相关
  caOrderHistoryVerification: any;
}

export default {
  waitingOrders: false,
  waitingInvoices: false,
  socketItem: false,
  caOrderHistoryVerification: false,
} as UtilSettings;
